import React from "react";

// Assets 
import "./TreatmentPlan.css";
import ComprehensiveBanner from "../../images/comprehensive-banner.jpg"

const TreatmentPlan = () => {
    return (
        <div className="TreatmentPlan">
            
            {/* Left Side */}
            <div className="phistory-left-side">
                <p className="htext">
                    The attending psychologist will also provide you with a personalized treatment plan that is specific to your individual needs. Unlike traditional psychiatry, which often just adopts use of medications to treat mental conditions, we take a unique approach through talk therapy. However, when medication is required, it will be prescribed as part of a complete treatment plan. It is all customized to support your uniqueness and meet your specified needs.
                </p>
                <div className="btn btn-primary">Contact us Now</div>
            </div>
    
            {/* Right Side  */}
            <div className="phistory-right-side">
                <div className="title-ring">
                    <h2 className="title">Comprehensive Treatment Plan</h2>
                    <img src={ComprehensiveBanner} alt="your-personal-banner" className="banner" />
                </div>
            </div>
        </div>
      )
}

export default TreatmentPlan