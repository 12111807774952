import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";

// Assets
import "./Testimonials.css";
import UserOne from "../../images/user-one.png";
import UserTwo from "../../images/user-two.png";
import UserThree from "../../images/user-three.png";


// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const Testimonials = () => {
    // Update the state when the active slide changes
    const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className="Testimonials">
        
        {/* Left Side */}
        <div className="l-testimonials">
            <img src={UserOne} alt="user one" className={`user-one stack ${activeIndex === 0 ? 'active' : ''}`} />
            <div className="primary stack"></div>
            <img src={UserTwo} alt="user two" className={`user-two stack ${activeIndex === 1 ? 'active' : ''}`} />
            <div className="secondry stack"></div>
            <img src={UserThree} alt="user three" className={`user-three stack ${activeIndex === 2 ? 'active' : ''}`} />
        </div>
        
        
        {/* Right Side */}
        <div className="r-testimonials">
            <div className="testimonial-header">
                <h1 className="title">Testimonials</h1>
                <div className="line"></div>
            </div>
            <div className="testimonial-sub">Check what our client say about us!</div>
            
            <Swiper
                // install Swiper modules
                modules={[Navigation, Pagination, Autoplay]}
                autoplay={{delay:3500}}
                spaceBetween={50}
                slidesPerView={1}
                pagination={{ clickable: true }}
                // onSwiper={(swiper) => console.log(swiper)}
                onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
                className="custom-swiper"
            >
                <SwiperSlide>
                    <p className="quote">“I came to the Unforsaken on the advice of a friend. She gave me their number after we had lunch. We hugged and she asked: ‘What are you...”</p>
                    <h2 className="user">Adejoke</h2>
                </SwiperSlide>
                <SwiperSlide>
                    <p className="quote">"She gave me their number after we had lunch. We hugged and she asked: ‘What are you..."</p>
                    <h2 className="user">Tunde</h2>
                </SwiperSlide>
                <SwiperSlide>
                    <p className="quote">"Worked with Dr. Busola Olamuyiwa on my ADHD. She gave me amazing tools to help me be more effective in my daily..."</p>
                    <h2 className="user">Lade</h2>
                </SwiperSlide>
            </Swiper>

        </div>
    </div>
  )
}

export default Testimonials;