import React from "react";
import { NavLink as Link } from "react-router-dom";

// Assets
import "./PatientCareProgram.css";
import PatientCareBanner from "../../images/in-patient-care-banner.jpg";
import heroBG from "../../images/img-bg.svg";

import RoundClock from "../../images/icons/24-hours.png";
import TherapeuticEnvironment from "../../images/icons/therapeutic-environment.png";
import EvidenceBasedTherapies from "../../images/icons/evidence-based-therapies.png";
import HolisticApproach from "../../images/icons/holistic-approach.png";
import PersonalizedTreatment from "../../images/icons/personalized-treatment-plans.png";
import NeurologicalBanner from "../../images/Inpatient-care.jpeg";
import PersonalHistoryBanner from "../../images/your-personal-banner.jpg"
import ComprehensiveBanner from "../../images/comprehensive-banner.jpg"

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

const PatientCareProgram = () => {

  // Click event that scroll to the top of the page
  const handleClickScroll = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="PatientCareProgram">
        {/* Header Section */}
        <Header/>
        
        {/* Hero Section */}
        <div className="landHero">
            <div className="hero-left-side">
                <div className="hero-head-text">Our In-Patient Care Program</div>
                <p className="hero-text">
                    Comprehensive In-Patient Mental Health Treatment at the Unforsaken House At the Unforsaken House, we understand that some mental health challenges require more intensive support than outpatient care can provide. Our In-Patient Care Program offers a safe, structured, and nurturing environment where you can focus on your recovery and well-being with the support of our dedicated team of mental health professionals.
                </p>
            </div>
            <div className="hero-right-side">
                <img src={PatientCareBanner} alt="About us Hero banner" className="stack inner-hero" />
                <img src={heroBG} alt="Hero-BG" className="stack img-bg" />
            </div>
        </div>
        {/* end Hero Section */}

        {/* Why Choose our Program */}
        <div className="CoreValues">
            <div className="core-top">
                <h1 className="in-p-title">Why Choose Our In-Patient Care Program?</h1>
            </div>
            <div className="core-bottom">
                <div className="value-box">
                    <div className="value-b-title">
                        <div className="value-icon pcp-border">
                            <img src={PersonalizedTreatment} alt="Icon" className="in-p-icon" />
                        </div>
                        <div className="box-title pcp-title">Personalized Treatment Plans</div>
                    </div>
                    
                    <div className="box-text">Each patient receives a comprehensive assessment upon admission to develop a personalized treatment plan tailored to their specific needs. Our multidisciplinary team works together to ensure that every aspect of your mental health is addressed.</div>
                </div>
                
                <div className="value-box">
                    <div className="value-b-title">
                        <div className="value-icon pcp-border">
                            <img src={RoundClock} alt="Icon" className="in-p-icon" />
                        </div>
                        <div className="box-title pcp-title">24/7 Support</div>
                    </div>
                    
                    <div className="box-text">Our in-patient facility is staffed around the clock by experienced clinical psychologists, psychiatrists, nurses, and support staff. We provide continuous care and support to help you navigate your mental health journey.</div>
                </div>

                <div className="value-box">
                    <div className="value-b-title">
                        <div className="value-icon pcp-border">
                            <img src={TherapeuticEnvironment} alt="Icon" className="in-p-icon" />
                        </div>
                        <div className="box-title pcp-title">Therapeutic Environment</div>
                    </div>
                    
                    <div className="box-text">Our facility is designed to offer a peaceful and healing atmosphere. With comfortable accommodations and various therapeutic amenities, we create an environment conducive to recovery and personal growth.</div>
                </div>

                <div className="value-box">
                    <div className="value-b-title">
                        <div className="value-icon pcp-border">
                            <img src={EvidenceBasedTherapies} alt="Icon" className="in-p-icon" />
                        </div>
                        <div className="box-title pcp-title">Evidence-Based Therapies</div>
                    </div>
                    
                    <div className="box-text">We utilize a range of evidence-based therapies, including Cognitive Behavioral Therapy (CBT), Dialectical Behavior Therapy (DBT), mindfulness practices, and more. These therapies are integrated into your daily schedule to promote healing and resilience.</div>
                </div>
                
                {/* Holistic Approach */}
                <div className="value-box">
                    <div className="value-b-title">
                        <div className="value-icon pcp-border">
                            <img src={HolisticApproach} alt="Icon" className="in-p-icon" />
                        </div>
                        <div className="box-title pcp-title">Holistic Approach</div>
                    </div>
                    
                    <div className="box-text">In addition to traditional therapeutic interventions, we offer holistic treatments such as art therapy, music therapy, yoga, and meditation. These complementary therapies support overall well-being and enhance the therapeutic process.</div>
                </div>

            </div>
        </div>
        {/* end Why Choose our Program */}

        {/* Conditions We Treat */}
        <div className="Neurological pcp-neuro">
            <div className="neu-cont">
                <div className="left-neuro">
                    <img src={NeurologicalBanner} alt="Neurological Banner" className="neuro-banner pcp-ba" />
                </div>
                <div className="right-neuro">
                    <h1 className="neuro-title">Conditions We Treat</h1>
                    <p className="neuro-text">
                        Our In-Patient Care Program is designed to support individuals with a variety of mental health conditions, including: <br />
                        1. Severe Anxiety and Depression <br />
                        2. Bipolar Disorder <br />
                        3. Schizophrenia and Psychotic Disorders <br />
                        4. Trauma and Post-Traumatic Stress Disorder (PTSD) <br />
                        5. Obsessive-Compulsive Disorder (OCD)<br />
                        6. Eating Disorders <br />
                    </p>
                    <Link 
                        to="/conditions" 
                        className="btn btn-primary btn-margin" 
                        onClick={handleClickScroll}
                    >
                        View all conditions
                    </Link>
                </div>
            </div>
        </div>
        {/* end Conditions We Treat */}

        {/* Family Involvement */}
        <div className="PersonalHistory">
        
            {/* Left Side */}
            <div className="phistory-left-side">
                <div className="title-ring">
                    <h2 className="title">Family Involvement</h2>
                    <img src={PersonalHistoryBanner} alt="your-personal-banner" className="banner" />
                </div>
            </div>

            {/* Right Side  */}
            <div className="phistory-right-side">
                <p className="htext">
                    We believe that involving family members in the treatment process can be crucial for recovery. Our program includes family therapy sessions and education to help your loved ones understand and support your journey. 
                </p>
                <h2 className="title">Discharge Planning and Aftercare</h2>
                <p className="htext">
                    Our commitment to your well-being doesn’t end when you leave our facility. We provide comprehensive discharge planning and aftercare support to ensure a smooth transition back to your daily life. This includes outpatient therapy, support groups, and other resources to help you maintain your progress. 
                </p>
            </div>
        </div>
        {/* end Family Involvement */}

        {/* Take the First Step Towards Healing */}
        <div className="TreatmentPlan">
            
            {/* Left Side */}
            <div className="phistory-left-side">
                <h2 className="title pcpn-text">Take the First Step Towards Healing</h2>
                <p className="htext">
                    If you or a loved one is struggling with severe mental health issues, our In-Patient Care Program at the Unforsaken House is here to provide the intensive support needed for recovery. Contact us today to learn more about our program and how we can help you achieve lasting well-being.
                </p>
                <Link
                    className="btn btn-primary"
                    to="/contact-us" 
                    onClick={handleClickScroll}
                >
                    Contact us Now
                </Link>
            </div>
    
            {/* Right Side  */}
            <div className="phistory-right-side">
                <div className="title-ring">
                    <h2 className="title">&nbsp;</h2>
                    <img src={ComprehensiveBanner} alt="your-personal-banner" className="banner" />
                </div>
            </div>
        </div>
        {/* end Take the First Step Towards Healing */}



        <Footer/>
    </div>
  )
}

export default PatientCareProgram