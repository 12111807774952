import React from "react";
import { NavLink as Link } from "react-router-dom";

// Assets
import "./BehaviourAddictions.css";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { FaCirclePlay } from "react-icons/fa6";
import BehaviourAddictionsHero from "../../images/behaviour-addictions-banner.jpg";
import heroBG from "../../images/img-bg.svg";

const BehaviourAddictions = () => {
  
  // Click event that scroll to the top of the page
  const handleClickScroll = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="BehaviourAddictions">
      <Header/>

        {/* Hero Section */}
        <div className="landHero">
            <div className="hero-left-side">
                <div className="hero-head-text no-justify">Behaviour Addictions</div>
                <p className="hero-text">
                  This involves any form of addiction that involves a compulsion to engage in the activity despite a negative consequences associated with the activity. This addiction can affect the individual’s normal daily functioning and may impact on the physical, emotional, mental, financial and social well-being. Behavioral addictions  could including drug addiction, phone addition, television addiction, overeating or gambling. The individual with such addiction may rationalize why they feel they carry out such activities, because for them engaging in such activity makes them feel ‘good’.
                </p>
                <Link to="/contact-us" onClick={handleClickScroll}><div className="btn btn-secondry">Contact Us Now</div></Link>
            </div>
            <div className="hero-right-side">
                <img src={BehaviourAddictionsHero} alt="banner" className="stack inner-hero lhero-banner" />
                <img src={heroBG} alt="Hero-BG" className="stack img-bg" />
            </div>
        </div>
        {/* end Hero Section */} 

        {/* Signs of addictive behaviors  */}
        <div className="Welcome" style={{alignItems: "flex-start"}}>
          <h1 className="welcome-header" style={{textAlign: "left"}}>Signs of Addictive Behaviors</h1>
          <div className="treat-point" style={{textAlign: "left"}}><FaCirclePlay className="point-icon" /><p className="point-text">Inability to Stop</p></div>
          <div className="treat-point" style={{textAlign: "left"}}><FaCirclePlay className="point-icon" /><p className="point-text">Continuous Engagement Despite Negative Consequences</p></div>
          <div className="treat-point" style={{textAlign: "left"}}><FaCirclePlay className="point-icon" /><p className="point-text">They Are Preoccupied with the addiction</p></div>
          <div className="treat-point" style={{textAlign: "left"}}><FaCirclePlay className="point-icon" /><p className="point-text">They may be changes in behaviour which is usually negative to suit their cravings</p></div>
          <div className="treat-point" style={{textAlign: "left"}}><FaCirclePlay className="point-icon" /><p className="point-text">Increased frequency of the addictive behaviors</p></div>
        </div>        
        {/* end Signs of addictive behaviors  */}

        <div className="Welcome" style={{alignItems: "flex-start"}}>
          <h1 className="welcome-header" style={{textAlign: "left"}}>There are four elements of addictions</h1>
          <p className="sub-text" style={{textAlign: "left"}}><span>Compulsion</span>: This involves an irresistible or uncontrolled urge to engage in the activity</p>
          <p className="sub-text" style={{textAlign: "left"}}><span>Cravings</span>: This an intense, urgent, or unusual desire or longing to engage in the behavior</p>
          <p className="sub-text" style={{textAlign: "left"}}><span>Consequences</span>: This is usually the outcome when the addictive behaviour is done. Consequences of addictive behaviour is usually negative and undesirable and could pose harm to the individual involved</p>
          <p className="sub-text" style={{textAlign: "left"}}><span>Control</span>: This is the ability of the individual to regulate the frequency of the addictive behavior. Control is usually for these individuals due to the compulsion to carry out these behaviors</p>
        </div>

        <div className="Welcome" style={{alignItems: "flex-start"}}>
          <h1 className="welcome-header" style={{textAlign: "left"}}>A good number of therapy can be effective in working with individuals with addictive behaviours.</h1>
          <p className="sub-text" style={{textAlign: "left"}}><span>Psychotherapy</span>: This will help the individual identify his/her triggers. Cognitive Behavioural Therapy (CBT) will also help in addressing thoughts that lead to the behaviours. </p>
          <p className="sub-text" style={{textAlign: "left"}}><span>Support groups</span>: Support groups will help individuals dealing with varying addictions. It is important that the individual identifies with the groups with similar addictive behaviours.</p>          
          <br />
          <Link to="/contact-us" onClick={handleClickScroll}><div className="btn btn-primary">Contact Us Now</div></Link>
        </div>        
      <Footer/>
    </div>
  )
}

export default BehaviourAddictions