import React from "react";
import { NavLink as Link } from "react-router-dom";
// import { PopupButton } from "react-calendly";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import PDBanner from "../../images/personality-disorder-banner.jpg";
import heroBG from "../../images/img-bg.svg";
import { FaCirclePlay } from "react-icons/fa6";
import WelcomeBanner from "../../images/about-welcome-banner.jpg";

const APersonalityDisorder = () => {
  
    // Click event that scroll to the top of the page
    const handleClickScroll = () => {
        window.scrollTo(0, 0);
    };

  return (
    <div className="APersonalityDisorder">
        <Header/>

        {/* Hero Section */}
        <div className="landHero">
            <div className="hero-left-side">
                <div className="hero-head-text no-justify">A Personality Disorder</div>
                <p className="hero-text">
                A personality disorder is a mental health condition where people have a lifelong pattern of seeing themselves and reacting to others in ways that cause challenges in their relationships. People with personality disorders find it difficult coping with distress, regulating their emotions and maintaining healthy relationships. Most people with personality disorders do not perceive it as a problem or may sometime rationalize it.
                </p>
                <Link to="/contact-us" onClick={handleClickScroll}><div className="btn btn-secondry">Contact Us Now</div></Link>
            </div>
            <div className="hero-right-side">
                <img src={PDBanner} alt="banner" className="stack inner-hero lhero-banner" />
                <img src={heroBG} alt="Hero-BG" className="stack img-bg" />
            </div>
        </div>
        {/* end Hero Section */}


        {/* Diagnosis */}
        <div className="AboustUsWelcome">
            <div className="about-welcome-left points">
                <div className="section-title">
                    <h1 className="title">Diagnosis</h1>
                    <div className="line"></div>
                </div>
                <p className="point-text">Diagnosis is only applicable to individuals 18 or older. People under 18 are typically not diagnosed with personality disorders because their personalities are still under development.</p>
                <p className="point-text"><FaCirclePlay className="point-icon" />Cognition (ways or perceiving and interpreting self, others, and events)</p>
                <p className="point-text"><FaCirclePlay className="point-icon" />Affectivity</p>
                <p className="point-text"><FaCirclePlay className="point-icon" />Interpersonal Functioning</p>
                <p className="point-text"><FaCirclePlay className="point-icon" />Impulse control</p><br />
                <p className="point-text">In order words it must affect at least two of these areas:</p><br />
                <p className="point-text"><FaCirclePlay className="point-icon" />Way of thinking about oneself and others</p>
                <p className="point-text"><FaCirclePlay className="point-icon" />Way of responding emotionally</p>
                <p className="point-text"><FaCirclePlay className="point-icon" />Way of relating to other people</p>
                <p className="point-text"><FaCirclePlay className="point-icon" />Way of controlling one’s behavior</p><br />
                <Link to="/book-session"  onClick={handleClickScroll}><div className="btn btn-primary">Book a Session</div></Link>
                {/* <PopupButton
                    url="https://calendly.com/theunforsaken"
                    rootElement={document.getElementById("root")}
                    text="Book a Session"
                    className="btn btn-primary"
                    styles={{whiteSpace: "nowrap", width: "180px"}}
                /> */}
            </div>
            <div className="about-welcome-right">
                <img src={WelcomeBanner} alt="About Us Welcome Banner" className="welcome-banner" />
            </div>
        </div>
        {/* end Diagnosis */}

        {/* Types of Personality Disorders   */}
        <div className="Seperator"></div>
        <div className="Welcome">
            <h1 className="welcome-header">Types of Personality Disorders</h1>
            <p className="sub-text" style={{textAlign: "left"}}><span>Antisocial personality disorder</span>: a pattern of disregarding or violating the rights of others. A person with antisocial personality disorder may not conform to social norms, may repeatedly lie or deceive others, or may act impulsively.</p>
            <p className="sub-text" style={{textAlign: "left"}}><span>Avoidant personality disorder</span>: a pattern of extreme shyness, feelings of inadequacy, and extreme sensitivity to criticism. People with avoidant personality disorder may be unwilling to get involved with people unless they are certain of being liked, be preoccupied with being criticized or rejected, or may view themselves as not being good enough or socially inept.</p>
            <p className="sub-text" style={{textAlign: "left"}}><span>Borderline personality disorder</span>: a pattern of instability in personal relationships, intense emotions, poor self-image and impulsivity. A person with borderline personality disorder may go to great lengths to avoid being abandoned, have repeated suicide attempts, display inappropriate intense anger, or have ongoing feelings of emptiness.</p>
            <p className="sub-text" style={{textAlign: "left"}}><span>Dependent personality disorder</span>: a pattern of needing to be taken care of and submissive and clingy behavior. People with dependent personality disorder may have difficulty making daily decisions without reassurance from others or may feel uncomfortable or helpless when alone because of fear of inability to take care of themselves.</p>
            <p className="sub-text" style={{textAlign: "left"}}><span>Histrionic personality disorder</span>: a pattern of excessive emotion and attention-seeking. People with histrionic personality disorder may be uncomfortable when they are not the center of attention, may use physical appearance to draw attention to themselves or have rapidly shifting or exaggerated emotions.</p>
            <p className="sub-text" style={{textAlign: "left"}}><span>Narcissistic personality disorder</span>: a pattern of need for admiration and lack of empathy for others. A person with narcissistic personality disorder may have a grandiose sense of self-importance, a sense of entitlement, take advantage of others or lack empathy.</p>
            <p className="sub-text" style={{textAlign: "left"}}><span>Obsessive-compulsive personality disorder</span>: a pattern of preoccupation with orderliness, perfection and control. A person with obsessive-compulsive personality disorder may be overly focused on details or schedules, may work excessively, not allowing time for leisure or friends, or may be rigid in their morality and values.</p>
            <p className="sub-text" style={{textAlign: "left"}}><span>Paranoid personality disorder</span>: a pattern of being suspicious of others and seeing them as mean or spiteful. People with paranoid personality disorder often assume people will harm or deceive them and don’t confide in others or become close to them.</p>
            <p className="sub-text" style={{textAlign: "left"}}><span>Schizoid personality disorder</span>: being detached from social relationships and expressing little emotion. A person with schizoid personality disorder typically does not seek close relationships, chooses to be alone and seems to not care about praise or criticism from others.</p>
            <p className="sub-text" style={{textAlign: "left"}}><span>Schizotypal personality disorder</span>: a pattern of being very uncomfortable in close relationships, having distorted thinking and eccentric behavior. A person with schizotypal personality disorder may have odd beliefs or odd or peculiar behavior or speech or may have excessive social anxiety.</p>
            <Link to="/book-session"  onClick={handleClickScroll}><div className="btn btn-primary">Book a Session</div></Link>
        </div>        
        {/* end Types of Personality Disorders   */}

        {/* Treatment */}
        <div className="Welcome" style={{alignItems: "flex-start"}}>
            <h1 className="welcome-header">Treatment</h1>
            <p className="sub-text" style={{textAlign: "left"}}>Treatment for personality disorder varies. Howver, some psychotherapieshave been shown to be more effective. They include:</p>
            <div className="treat-point"><FaCirclePlay className="point-icon" /><p className="point-text">Psychoanalytic/psychodynamic/transference-focused therapy</p></div>
            <div className="treat-point"><FaCirclePlay className="point-icon" /><p className="point-text">Dialectical behavior therapy</p></div>
            <div className="treat-point"><FaCirclePlay className="point-icon" /><p className="point-text">Cognitive behavioral therapy</p></div>
            <div className="treat-point"><FaCirclePlay className="point-icon" /><p className="point-text">Group therapy</p></div>
            <div className="treat-point"><FaCirclePlay className="point-icon" /><p className="point-text">Psychoeducation (teaching the individual and family members about the diagnosis, treatment and ways of coping)</p></div>
            <p className="sub-text" style={{textAlign: "left", marginBottom: 14, marginTop: 26}}>There are no medications specifically used to treat personality disorders. However, in some cases, medication, such as antidepressants, anti-anxiety medication or mood-stabilizing medication, may be helpful in treating some symptoms especially if it is co-morbid with other mental conditions.</p>
            <p className="sub-text" style={{textAlign: "left"}}>Psychotherapy can help a person understand the effects of their behavior may be having on themselves and others. Through therapy they will learn to manage or cope with symptoms and to reduce behaviors causing dysfunctionality in their relationships.</p>
            
            <Link to="/book-session"  onClick={handleClickScroll}><div className="btn btn-primary">Book a Session</div></Link>
        </div>
        {/* end Treatment */}

        <Footer/>
    </div>
  )
}

export default APersonalityDisorder;