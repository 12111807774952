// Assets

import ADHD from "../../images/adhd.jpg";
import PersonalityDisorder from "../../images/personality-disorder.jpg";
import AngerIssues from "../../images/anger-issues.jpg";
import Autism from "../../images/autism.jpg";
import BehaviourAddictions from "../../images/behaviour-addictions.jpg";
import BehaviourProblems from "../../images/behaviour-problems.jpg";
import BipolarDisorder from "../../images/bipolar-disorder.jpg";
import BodyDysmorphicDisorder from "../../images/body-dysmorphic-disorder.jpg";
import BorderlinePersonality from "../../images/borderline-personality-disorder.jpg";
import Depression from "../../images/depression.jpg";
import DissociativeDisorders from "../../images/dissociative-disorders.jpg";
import DrugsAlcoholAddiction from "../../images/drugs-alcohol-addiction.jpg";
import EatingDisorders from "../../images/eating-disorders.jpg";
import MaritalProblems from "../../images/marital-problems.jpg";
import NarcissisticPersonality from "../../images/narcissistic-personality-disorder.jpg";

// Team Assets
import Busola from "../../images/dr-busola.png";
import BusolaTwo from "../../images/Busola-Olamuyiwa-2.jpg";

import John from "../../images/john-olusegun.png";
import JohnTwo from "../../images/john-olusegun2.jpg";

import Vinella from "../../images/vinella.png";
import VinellaTwo from "../../images/Vinella-Mbanusi2.jpg";

import Obatomi from "../../images/dr-obatomi.png";
import ObatomiTwo from "../../images/dr-obatomi2.jpg";

import Olubiyi from "../../images/omobosolaoluwa.png";
import OlubiyiTwo from "../../images/Olubiyi2.jpg";

import DanielDada from "../../images/daniel-dada.png";
import DanielDadaTwo from "../../images/daniel-dada2.jpg";



// Consultants’ Rate
export const consultantsRates = [
    {
        sn: "1",
        serviceName: "Initial Consultation (For clients 16 years and above)",
        priceNaira: "₦60,000",
        pricePound: "£60",
    },
    {
        sn: "2",
        serviceName: "Initial Consultation (For clients between 1-15 years)",
        priceNaira: "₦60,000",
        pricePound: "£60",
    },
    {
        sn: "3",
        serviceName: "Follow-up Initial Consultation (For Clients 16 years and above)",
        priceNaira: "₦60,000",
        pricePound: "£60",
    },
    {
        sn: "4",
        serviceName: "Follow-up Initial Consultation (For Clients 1-15 years)",
        priceNaira: "₦60,000",
        pricePound: "£60",
    },
    {
        sn: "5",
        serviceName: "Psychotherapy Sessions (50 minutes session) – (For Clients 16 years and above)",
        priceNaira: "₦60,000",
        pricePound: "£60",
    },
    {
        sn: "6",
        serviceName: "Psychotherapy Sessions (45 minutes session) – (For clients between 1 - 15 years)",
        priceNaira: "₦60,000",
        pricePound: "£60",
    },
    {
        sn: "7",
        serviceName: "Cognitive Behavioral Therapy (CBT) Session (50 minutes session)",
        priceNaira: "₦60,000",
        pricePound: "£60",
    },
]
// Psychologist’ Rate
export const psychologistsRates = [
    {
        sn: "1",
        serviceName: "Initial Consultation by other psychologists (For clients 16 years and above",
        priceNaira: "₦55,000",
        pricePound: "£55",
    },
    {
        sn: "2",
        serviceName: "Initial Consultation by other psychologists (For clients between 1-15 years)",
        priceNaira: "₦55,000",
        pricePound: "£55",
    },
    {
        sn: "3",
        serviceName: "Follow-up Initial Consultation (For Clients 16 years and above)",
        priceNaira: "₦45,000",
        pricePound: "£45",
    },
    {
        sn: "4",
        serviceName: "Follow-up Initial Consultation (For Clients 1-15 years)",
        priceNaira: "₦45,000",
        pricePound: "£45",
    },
    {
        sn: "5",
        serviceName: "Psychotherapy Sessions (50 minutes session) – (For clients 16 years and above)",
        priceNaira: "₦45,000",
        pricePound: "£45",
    },
    {
        sn: "6",
        serviceName: "Psychotherapy Sessions (45 minutes session) – (For clients between 1 - 15 years)",
        priceNaira: "₦45,000",
        pricePound: "£45",
    },
    {
        sn: "7",
        serviceName: "Cognitive Behavioral Therapy (CBT) Session (50 minutes session)",
        priceNaira: "₦45,000",
        pricePound: "£45",
    },
]

// Rates
export const otherRates = [
    {
        sn: "1",
        serviceName: "Psychological Assessments - (MMPI, DAP, ISBA)",
        priceNaira: "₦90,000",
        pricePound: "£90",
    },    
    {
        sn: "2",
        serviceName: "Psychological Assessments - (MMPI, DAP, ISBA)",
        priceNaira: "₦90,000",
        pricePound: "£90",
        note: " Kindly note that couple’s/group sessions are billed separately from individual session"
    },    
    {
        sn: "3",
        serviceName: "Psychiatric Evaluation",
        priceNaira: "₦55,000",
        pricePound: "£55",
    },    
    {
        sn: "4",
        serviceName: "Tele/Phone Consults",
        priceNaira: "₦60,000",
        pricePound: "£60",
    },    
    {
        sn: "5",
        serviceName: "Feedback Session",
        priceNaira: "No Charge",
        pricePound: "No Charge",
    },    
    {
        sn: "6",
        serviceName: "Cognitive Assessment (WISC)",
        priceNaira: "₦250,000",
        pricePound: "£250",
    },    
    {
        sn: "7",
        serviceName: "Mental Health Training",
        priceNaira: "₦75,000",
        pricePound: "£75",
    },    
    {
        sn: "8",
        serviceName: "Psychologist’s Call out Service (within Lagos)",
        priceNaira: "₦150,000",
        pricePound: "£150",
        note: " Per Individual"
    },    
    {
        sn: "9",
        serviceName: "Psychologist’s Call out Service (Outside Lagos)",
        priceNaira: "₦300,000",
        pricePound: "£300",
        note: " Per Individual | Outside Lagos but within Nigeria"
    },    
    {
        sn: "10",
        serviceName: "Psychiatrist’s Call out service (within Lagos)",
        priceNaira: "₦150,000",
        pricePound: "£150",
        note: " Per Individual"
    },    
    {
        sn: "11",
        serviceName: "Psychiatrist’s Call out Service (Outside Lagos)",
        priceNaira: "₦300,000",
        pricePound: "£300",
        note: " Per Individual | Outside Lagos but within Nigeria"
    },    
]



// our Team
export const TeamData = [
    {
        teamPicture: Busola,
        teamBioPic: BusolaTwo,
        teamRole: "Lead Psychologist | Director",
        teamDetailRole: "CONSULTANT/LEAD PSYCHOLOGIST/DIRECTOR",
        teamName: "Dr. Busola Olamuyiwa",
        teamBio: "Busola is a member of the British Psychological Society’s verified test user with Membership No: RQTU 486391. She is also the present Public Relation Officer (PRO) of the Nigerian Association of Clinical Psychologists (NACP, Lagos Chapter) with the (NACP License number 000198) and a member of the Psychological Society of Ireland (PSI). She works with clients to improve their emotional well-being, coping skills and other life’s skills using well researched modern psychological interventions. Her role is to ensure that her patients maximize their potential and minimize the distress associated with their problems. She has clinical expertise in the management of depression, anxiety, self-harm, emotional issues, managing teenage challenging behaviours, behavioural issues, adjustment to physical illness, schizophrenia, childhood trauma, bullying, stress management, sexual abuse, sleep problems and neurological disorders (Autism, ADHD and other related problems). Busola has a published article on ‘the relationships between length in employment, workload, and burnout among employees and care givers of people with Intellectual disability’. She has extensive knowledge and clinical practice on reduction of organizational burnout and managing stress.",
    },
    {
        teamPicture: John,
        teamBioPic: JohnTwo,
        teamRole: "Clinical Psychologist",
        teamDetailRole: "CLINICAL PSYCHOLOGIST",
        teamName: "John Olusegun",
        teamBio: "John is an active member of the Nigerian Psychological Association (NPA) and a registered member of the Nigerian Association of Clinical Psychologists (NACP) with the registration number FM00237. He is licensed to practice psychological assessments and interventions. He is currently the secretary to the forensic research group at Federal Neuropsychiatric hospital Yaba. Mr. John has specialty in administration, analysis and interpretation of psychometric tests. He is an addiction professional who has recorded success in helping people with addictions. He is a certified Global Mental Health professional and Leadership and management in Health professional by the University of Washington, USA in the year 2021.",
    },
    {
        teamPicture: Vinella,
        teamBioPic: VinellaTwo,
        teamRole: "Psychologist | Centre Coordinator",
        teamDetailRole: "PSYCHOLOGIST AND CENTRE COORDINATOR",
        teamName: "Vinella Mbanusi",
        teamBio: "Qualifications: B.Sc. Psychology, B.Ed. Guidance and Counselling. Vinella obtained her degrees from Nnamdi Azikwe University, Awka, Anambra State Nigeria. She has over 5 years work experience in mental health, counselling, psychotherapy, developmental psychology and youth mentorship. She has worked with individuals across all age groups in achieving mental wellness. She worked with clients from Federal Neuropsychiatric hospital Yaba, Lagos, media houses, youth resources centres and schools in promoting mental awareness. She has also worked with Canadian NGO (CUSO) in helping young men and women improve their mental well-being through mental health programs, employability and career advancement outreach. She adopts psychological skills that will enhance individual’s coping skills, mental wellness, adjustment skills, productivity and interpersonal relationship. Her major focus is helping individuals alleviate or eliminate different forms of mental distress.  She has successfully worked with individuals manage or eliminate anxiety, depression, grief, stress, trauma, eating disorders, autism, ADHD, relationship problems and other mental conditions. She works with hospitals, teenage training outreaches, mental health centers including cancer centers to increase mental awareness. She has a developed passion and drive to work with people in finding solutions to their psychological, psychosocial, emotional, educational, work related and other life challenges. She enjoys to work with children and adults alike.",
    },
    {
        teamPicture: Obatomi,
        teamBioPic: ObatomiTwo,
        teamRole: "Consultant Psychiatrist",
        teamDetailRole: "PSYCHIATRIST",
        teamName: "Dr. Obatomi Alalade",
        teamBio: "Dr. Obatomi Alalade is a young, energetic and dynamic mental health specialist. He is a member of the West Africa College of Physicians with special interest in General Adult psychiatry and Addiction Psychiatry. Some of the trainings he has received include certification in Rational Emotive behavioral therapy, Universal recovery curriculum for Nigeria in delivering recovery support services using the Allies model, leadership management in health amongst others. His hobbies include listening to people and providing support.",
    },
    {
        teamPicture: Olubiyi,
        teamBioPic: OlubiyiTwo,
        teamRole: "Psychologist",
        teamDetailRole: "PSYCHOLOGIST",
        teamName: "Omobosolaoluwa Olubiyi",
        teamBio: "Bosola holds a Bachelor of Science degree in Psychology from Webster University. He has also received an advanced diploma from the Institute of Counselling, Nigeria (LIC). He has a strong enthusiasm for psychology, is very motivated, and is patient-focused. He has worked with children who have special needs and assisted in counseling, among other things. Bosola aspires to complete a master’s degree in industrial psychology, and his long-term objective is to establish a mental health foundation in Nigeria that will work to offer everyone access to high-quality, affordable, mental health care.",
    },
    {
        teamPicture: DanielDada,
        teamBioPic: DanielDadaTwo,
        teamRole: "Social Media Manager",
        teamDetailRole: "SOCIAL MEDIA MANAGER",
        teamName: "Daniel Opeyemi Dada",
        teamBio: "Daniel Opeyemi Dada is a dedicated Social Media Manager and Digital Marketer with a strong background in creative content creation, data-driven decision-making, and staying abreast of industry trends. He excels in developing comprehensive social media strategies, engaging with online communities, and analyzing performance metrics to drive growth and improve conversion rates.",
    },
]

// FAQs Data
export const FaqsData = [
    {
        question: "Are the services confidential?",
        answer: "Yes, confidentiality is typically a priority in mental health and wellness organizations. Your personal information and discussions with therapists or counselors are usually kept private, as governed by relevant laws and ethical guidelines."
    },
    {
        question: "Is teletherapy available at The Unforsaken House?",
        answer: "Absolutely! We offer convenient teletherapy options, allowing you to receive support from the comfort of your home. Virtual sessions are conducted securely and professionally."
    },
    {
        question: "What age groups do you cater to?",
        answer: "We provide services for individuals of all age groups, from children and adolescents to adults and seniors, addressing a wide range of mental health concerns."
    },
]


// Conditions Data
export const ConditionsData = [
    {
        conditionsIcon: ADHD,
        title: "Attention Deficient Hyperactivity Disorder",
        description: "Attention Deficient Hyperactivity Disorder (ADHD) is neurodevelopmental disorder that affects an individual’s development and normal daily functioning. It is characterized by persistent issues like inattention, hyperactivity or impulsive behaviour.",
        link: "conditions/adhd"
    },
    {
        conditionsIcon: PersonalityDisorder,
        title: "A Personality Disorder",
        description: "A personality disorder is a mental health condition where people have a lifelong pattern of seeing themselves and reacting to others in ways that cause challenges in their relationships.",
        link: "conditions/personality-disorder"
    },
    {
        conditionsIcon: AngerIssues,
        title: "Anger Issues",
        description: "Anger is a normal human emotion. People at some point in time finds themselves in situation they lose their temper. However, when anger becomes recurring and begins to interfere with your relationship with people...",
        link: "conditions/anger-issues"
    },
    {
        conditionsIcon: Autism,
        title: "Autism",
        description: "Autism spectrum disorder (ASD) is developmental disability caused by differences in the brain activity, which affects the central nervous system.",
        link: "conditions/autism"
    },
    {
        conditionsIcon: BehaviourAddictions,
        title: "Behaviour Addictions",
        description: "This involves any form of addiction that involves a compulsion to engage in the activity despite a negative consequences associated with the activity.",
        link: "conditions/behaviour-addictions"
    },
    {
        conditionsIcon: BehaviourProblems,
        title: "Behaviour and School Problems",
        description: "The behavior and school problems involves any behavior of the child which create difficulties in his/her learning process.",
        link: "conditions/behaviour-school-problems"
    },
    {
        conditionsIcon: BipolarDisorder,
        title: "Bipolar Disorder",
        description: "Bipolar disorder is a mental illness characterized by unusual shift in an individual’s mood, energy and activities. These shifts can impair an individual’s normal daily functioning.",
        link: "conditions/bipolar-disorder"
    },
    {
        conditionsIcon: BodyDysmorphicDisorder,
        title: "Body Dysmorphic Disorder",
        description: "Body dysmorphic disorder is a mental health condition characterized by an individual’s inability to stop thinking about defects or flaws in their looks. These defects or flaws may be perceived, real and minor and may not be perceived by others.",
        link: "conditions/body-dysmorphic-disorder"
    },
    {
        conditionsIcon: BorderlinePersonality,
        title: "Borderline Personality Disorder",
        description: "Borderline personality disorder is a mental illness that is characterised by unstable moods, behaviour and relationships. It severely impacts a person’s ability to regulate their emotions",
        link: "conditions/borderline-personality-disorder"
    },
    {
        conditionsIcon: Depression,
        title: "Depression",
        description: "Depression is one of the commonest mental health conditions. It often presents with a low mood, sense of hopelessness, helplessness, loss of interest in previously enjoyed activity, changes in relationship, social withdrawal.",
        link: "conditions/depression"
    },
    {
        conditionsIcon: DissociativeDisorders,
        title: "Dissociative Disorders",
        description: "Dissociative disorders are mental health conditions that involve loss of connection between, feelings, thoughts, memories, surroundings, behavior and self-identity.",
        link: "conditions/dissociative-disorders"
    },
    {
        conditionsIcon: DrugsAlcoholAddiction,
        title: "Drugs and Alcohol Addiction",
        description: "Drug addiction otherwise known as substance use disorder, is a condition that affects a person’s brain and behaviour. It often leads to inability to control its use of a legal or illegal drug or medicine.",
        link: "conditions/drugs-alcohol-addiction"
    },
    {
        conditionsIcon: EatingDisorders,
        title: "Eating Disorders",
        description: "Eating disorders are serious health conditions that affect both one’s physical and mental health. These conditions include problems in how the person think about food, eating, weight and shape, and in the eating behaviors.",
        link: "conditions/eating-disorders"
    },
    {
        conditionsIcon: MaritalProblems,
        title: "Marital Problems",
        description: "Marital problems stem from poor communication, lack of intimacy, money problems, and growing apart as life takes different twists and turns.",
        link: "conditions/marital-problems"
    },
    {
        conditionsIcon: NarcissisticPersonality,
        title: "Narcissistic Personality Disorder",
        description: "Narcissistic personality disorder is a mental health condition that involves when people have an unreasonably high sense of their own importance. They need and seek too much attention and want people to admire them.",
        link: "conditions/Narcissistic-personality-disorder"
    },
]


