import React from "react";

// Assets
import "./CoreValues.css";
import Vision from "../../images/our-vision.svg";
import Mission from "../../images/our-mission.svg";

const CoreValues = () => {
  return (
    <div className="CoreValues">
        <div className="core-top">
            <div className="title-pill">Core Value</div>
            <p className="text">Our Support are: Genuine, Person centered, Non- judgmental, Relationship oriented
            Built on individual’s optimal capacity, Result oriented.</p>
        </div>
        <div className="core-bottom">
            <div className="value-box">
                <div className="value-icon">
                    <img src={Vision} alt="Vision Icon" className="icon" />
                </div>
                <div className="box-title">Our Vision</div>
                <div className="box-text">Our Support are: Genuine, Person centered, Non- judgmental, Relationship oriented</div>
            </div>
            <div className="value-box">
                <div className="value-icon">
                    <img src={Mission} alt="Vision Icon" className="icon" />
                </div>
                <div className="box-title">Our Mission</div>
                <div className="box-text">To deliver wholistic psychosocial support services to clients using modern psychological and scriptural principles. </div>
            </div>
        </div>
    </div>
  )
}

export default CoreValues