import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./ContactUsContent.css";
import ContactBanner from "../../images/contact-us-banner.jpg";
import ContactBG from "../../images/img-bg.svg";
import PhoneIcon from "../../images/calling.png";
import EmailIcon from "../../images/message.png";
import AddressIcon from "../../images/placeholder.png";
import { FaFacebook, FaInstagram, FaXTwitter, FaYoutube, FaLinkedinIn, FaTiktok } from "react-icons/fa6";
import Map from "../Maps/Maps";
// import Map from "../Maps/Maps";

const ContactUsContent = () => {
  const [data, setData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    message: ""
  });

  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate fields
    const newErrors = {};
    if (!data.first_name) newErrors.first_name = "First name is required";
    if (!data.last_name) newErrors.last_name = "Last name is required";
    if (!data.email || !/^\S+@\S+\.\S+$/.test(data.email)) newErrors.email = "Valid email is required";
    if (!data.message) newErrors.message = "Message is required";

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setErrors({});
      try {
        const response = await fetch("/submit.php", {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          },
          body: new URLSearchParams(data)
        });

        if (response.ok) {
          navigate("/success");
        } else {
          const errorText = await response.text();
          console.error("Error submitting form:", errorText);
          throw new Error("Error submitting form");
        }
      } catch (error) {
        console.error(error);
        setErrors({ submit: "An error occurred while sending the message. Please try again later." });
      }
    }
  };

  return (
    <div className="ContactUsContent">
      <div className="contact-left">
        <p className="contact-sub-header">Get in Touch</p>
        <h1 className="contact-header">Let's Have a Chat, Reach Out to Us</h1>
        <p className="contact-text">Have questions or feedback? We’re here to help. Send us a message, and we’ll respond within 24 hours</p>
        <div className="seperator"></div>
        <div className="email-section">
          <h1 className="contact-header">Send a Message</h1>
          <div className="contactUs-form">
            <form onSubmit={handleSubmit}>
              <label htmlFor="first_name">First Name<span className="highlight">*</span></label>
              <div className="two-col">
                <input
                  type="text"
                  id="first_name"
                  name="first_name"
                  className="input-form"
                  onChange={handleChange}
                  value={data.first_name}
                  placeholder="Enter your first name"
                  required
                />
                <input
                  type="text"
                  id="last_name"
                  name="last_name"
                  className="input-form"
                  onChange={handleChange}
                  value={data.last_name}
                  placeholder="Enter your surname"
                  required
                />
              </div>
              {errors.first_name && <span className="error">{errors.first_name}</span>}
              {errors.last_name && <span className="error">{errors.last_name}</span>}

              <label htmlFor="email">Email<span className="highlight">*</span></label>
              <div className="one-col">
                <input
                  type="text"
                  id="email"
                  name="email"
                  className="input-form"
                  onChange={handleChange}
                  value={data.email}
                  placeholder="mail@domain.com"
                  required
                />
              </div>
              {errors.email && <span className="error">{errors.email}</span>}

              <label htmlFor="message">Message<span className="highlight">*</span></label>
              <div className="one-col">
                <textarea
                  id="message"
                  name="message"
                  rows="12"
                  className="textarea"
                  onChange={handleChange}
                  value={data.message}
                  placeholder="Leave us a message"
                  required
                ></textarea>
              </div>
              {errors.message && <span className="error">{errors.message}</span>}

              <div className="two-col">
                <button type="submit" className="btn btn-secondry">Send</button>
              </div>
              {errors.submit && <span className="error">{errors.submit}</span>}
            </form>
          </div>
          <Map/>
        </div>
      </div>

      <div className="contact-right">
        <div className="top">
          <img src={ContactBanner} alt="Contact Banner" className="contact-banner" />
          <img src={ContactBG} alt="Contact Banner Background" className="contact-img-bg" />
        </div>
        <div className="mid">
          <div className="contact-pill green-pill">
            <div className="circle white-pill"><img src={PhoneIcon} alt="Phone Icon" /></div>
            <div className="pill-content">
              <h4 className="pill-header">Phone</h4>
              <p className="pill-text">
                08090572137 <br />09047569991
              </p>
            </div>
          </div>
          <div className="contact-pill white-pill">
            <div className="circle green-pill"><img src={EmailIcon} alt="Email Icon" /></div>
            <div className="pill-content">
              <h4 className="pill-header">Email Address</h4>
              <p className="pill-text">info@theunforsaken.org</p>
            </div>
          </div>
          <div className="contact-pill green-pill">
            <div className="circle white-pill"><img src={AddressIcon} alt="Address Icon" /></div>
            <div className="pill-content">
              <h4 className="pill-header">Address</h4>
              <p className="pill-text">46, Olabanji Olajide Street, Lekki Phase 1, Lagos, Nigeria</p>
            </div>
          </div>
        </div>
        <div className="contact-footer">
          <div className="seperator"></div>
          <div className="contact-footer-icons">
            <a href="https://web.facebook.com/people/The-Unforsaken-House/100063563652934/?mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
              <div className="cir"><FaFacebook className="contact-icon" /></div>
            </a>
            <a href="https://www.instagram.com/theunforsakenhouse/" target="_blank" rel="noopener noreferrer">
              <div className="cir"><FaInstagram className="contact-icon" /></div>
            </a>
            <a href="https://x.com/UHOH_psyclinic?t=xMMGIxdDdaj0A9rj8Vb53A&s=09" target="_blank" rel="noopener noreferrer">
              <div className="cir"><FaXTwitter className="contact-icon" /></div>
            </a>
            <a href="https://www.tiktok.com/@the.unforsaken.ho?_t=8oGAumcA6SL&_r=1" target="_blank" rel="noopener noreferrer">
              <div className="cir"><FaTiktok className="contact-icon" /></div>
            </a>
            {/* <div className="cir"><FaYoutube className="contact-icon" /></div>
            <div className="cir"><FaLinkedinIn className="contact-icon" /></div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsContent;
