import React from "react";

// Assets
import "./FeedbackTwo.css";
import InterventionBanner from "../../images/intervention-banner.jpg";
import BetterYou from "../../images/better-you-banner.jpg";

const FeedbackTwo = () => {
  return (
    <div className="FeedbackTwo">
        <div className="int-pill">
            <img src={InterventionBanner} alt="Intervention Plan Banner" className="pill-image" />
            <div className="pill-content">
                <h2 className="pill-title">Intervention Plan</h2>
                <p className="pill-text">
                    After the feedback session, we commence our treatment plan based on the recommendations. Therapy goals may change as therapy progresses. Your attending psychologist will discuss this with you, conduction therapy progress evaluation and review your therapy goals if required.
                </p>
            </div>
        </div>

        <div className="int-pill">
            <img src={BetterYou} alt="Intervention Plan Banner" className="pill-image" />
            <div className="pill-content">
                <h2 className="pill-title">Better You</h2>
                <p className="pill-text">
                    It will be our desire that you meet your therapy goals. We believe this will positively affect your general well-being, relationships and overall daily functioning.
                </p>
            </div>
        </div>

    </div>
  )
}

export default FeedbackTwo