import React from "react";
import { NavLink as Link } from "react-router-dom";

import "./ConditionItems.css";
import { ConditionsData } from "../data/Data";

const ConditionItems = () => {

  // Click event that scroll to the top of the page
  const handleClickScroll = () => {
    window.scrollTo(0, 0);
  };  
  return (
    <div className="ConditionItems">
        {ConditionsData.map((conData, i) => (
            <div className="condition-box" key={i}>
                <img src={conData.conditionsIcon} alt="" className="cond-imgage" />
                <div className="cond-content">
                    <h3 className="cond-title">{conData.title}</h3>
                    <p className="cond-text">{conData.description}</p>
                    <Link to={`/${conData.link}`} onClick={handleClickScroll}><div className="btn btn-primary">Learn More</div></Link>
                </div>
            </div>
        ))}
    </div>
  )
}

export default ConditionItems;