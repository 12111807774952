import React from "react";

// Assets
import "./UsDifferent.css";

const UsDifferent = () => {
  return (
    <div className="UsDifferent">
        <div className="section-title">
            <h1 className="title">What Makes Us Different?</h1>
            <div className="line"></div>
        </div>
        <p className="text">We employ a non-invasive, empathetic and friendly approach with all our clients. Our team is focused and have a strong desire to achieve. The specialists are skilled to work independently, however, depending on the severity of the symptom and specific diagnosis, we may employ a biopsychosocial approach. A biopsychosocial approach will ensure that all aspects of the treatment is covered.</p>
        <p className="text">We also specialize in Cognitive Behavioral Therapy as this evidence-based method has been shown to be effective in the treatment of depression and anxiety and other problematic symptoms related to thoughts, feelings and behavior.</p>
    </div>
  )
}

export default UsDifferent