import React from "react";
import { NavLink as Link } from "react-router-dom";

// Assets
import "./Footer.css";
import FooterLogo from "../../images/footer-logo.png";
import EmailForm from "../EmailForm/EmailForm";

import { 
    FaFacebook, 
    FaInstagram, 
    FaXTwitter, 
    // FaYoutube, 
    // FaLinkedinIn,
    FaTiktok,
    FaPhone,
    FaEnvelope
} from "react-icons/fa6";

const Footer = () => {

  // Click event that scroll to the top of the page
  const handleClickScroll = () => {
    window.scrollTo(0, 0);
  };  

  return (
    <div className="Footer">
        <div className="footer-main">
            <div className="about-footer">
                <img src={FooterLogo} alt="Footer Logo" className="footer-logo" />
                <p className="footer-text">The Unforsaken-House of Hephzibah is a professional and fully registered faith-based private Psychological facility in Lekki, Lagos State. We render qualitative Clinical Psychology services in line with experiences in Counselling, Mentoring, Psychological assessment and evaluation, Social Work, and Organizational work.</p>
                <Link to="/about-us" onClick={handleClickScroll}><div className="btn btn-primary">About Us</div></Link>
            </div>

            <ul className="footer-section">
              <li className="footer-head">Company</li>
              <li  className="footer-link"><Link to="/about-us" onClick={handleClickScroll}>About Us</Link></li>
              <li  className="footer-link"><Link to="/contact-us" onClick={handleClickScroll}>Contact Us</Link></li>
              <li  className="footer-link"><Link to="/our-approach" onClick={handleClickScroll}>Our Approach</Link></li>
              <li  className="footer-link"><Link to="/in-patient-care-program" onClick={handleClickScroll}>In Patient Care</Link></li>
              <li  className="footer-link"><Link to="/conditions" onClick={handleClickScroll}>Conditions</Link></li>
              <li  className="footer-link"><Link to="/blog" onClick={handleClickScroll}>Blog</Link></li>
            </ul>

            <ul className="footer-section">
              <li className="footer-head">Services</li>
              <li className="footer-link"><Link to="/our-approach" onClick={handleClickScroll}>Group Theraphy</Link></li>
              <li  className="footer-link"><Link to="/in-patient-care-program" onClick={handleClickScroll}>In Patient Care</Link></li>
              <li className="footer-link"><Link to="/our-approach" onClick={handleClickScroll}>Individual Therapy</Link></li>
              <li className="footer-link"><Link to="/our-approach" onClick={handleClickScroll}>Addiction Managment</Link></li>
              <li className="footer-link"><Link to="/our-approach" onClick={handleClickScroll}>Psychiatric Evaluation</Link></li>
              <li className="footer-link"><Link to="/our-approach" onClick={handleClickScroll}>EAP Services</Link></li>
              <li className="footer-link"><Link to="/our-approach" onClick={handleClickScroll}>Assessments</Link></li>
            </ul>

            <ul className="footer-section">
              <li className="footer-head">Connect With Us</li>
              <li className="icon-links">
                <a href="https://web.facebook.com/people/The-Unforsaken-House/100063563652934/?mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
                  <FaFacebook className="footer-icon" />
                </a>
                <a href="https://www.instagram.com/theunforsakenhouse/" target="_blank" rel="noopener noreferrer">
                  <FaInstagram className="footer-icon"/>
                </a>
                <a href="https://x.com/UHOH_psyclinic?t=xMMGIxdDdaj0A9rj8Vb53A&s=09" target="_blank" rel="noopener noreferrer">
                  <FaXTwitter className="footer-icon"/>
                </a>
                <a href="https://www.tiktok.com/@the.unforsaken.ho?_t=8oGAumcA6SL&_r=1" target="_blank" rel="noopener noreferrer">
                  <FaTiktok className="footer-icon"/>
                </a>
                {/* <FaYoutube className="footer-icon"/>
                <FaLinkedinIn className="footer-icon"/> */}
              </li>

              <li className="footer-head">Contact Us</li>
              <li className="footer-link contact-footer">
                <FaPhone className="footer-icon"/> 0809 057 2137
              </li>
              <li className="footer-link contact-footer contact-link">
                <FaEnvelope className="footer-icon"/> info@theunforsaken.org
              </li>


              <li className="footer-head">Join our Newsletter</li>
              <p className="footer-text">Join our community of support and take proactive steps towards a happier, healthier you. Sign up today to start receiving valuable resources tailored to your mental health and well-being goals.</p>
              <EmailForm/>
            </ul>
        </div>

        <div className="copyright">
          <div className="seperator"></div>
          <p className="copy-text">
            © 2024 <span className="highlight">TheUnforsaken House of Hephzibah</span>. All rights reserved.
          </p>
        </div>
    </div>
  )
}

export default Footer;