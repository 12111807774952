import React from "react";
// import { PopupButton } from "react-calendly";
import { NavLink as Link } from "react-router-dom";

// Assets
import "./ConditionsWelcome.css";

const ConditionsWelcome = () => {
    // Click event that scroll to the top of the page
    const handleClickScroll = () => {
      window.scrollTo(0, 0);
    }; 
    
  return (
    <div className="Welcome">
        <h1 className="welcome-header">At <span className="highlight">the Unforsaken House</span>, we provide comprehensive support for a wide range of mental health conditions</h1>
        <p className="sub-text conditions-text">Our team of experienced clinical psychologists is dedicated to helping you navigate life's challenges with compassion and evidence-based care. Below are some of the common conditions we treat</p>
        <Link to="/book-session"  onClick={handleClickScroll}><div className="btn btn-primary">Book a Session</div></Link>
        {/* <PopupButton
          url="https://calendly.com/theunforsaken"
          rootElement={document.getElementById("root")}
          text="Book a Session"
          className="btn btn-primary"
          styles={{whiteSpace: "nowrap", width: "180px"}}
        /> */}
    </div>
  )
}

export default ConditionsWelcome