import React, { useEffect } from "react";
import "./EmailForm.css";

const EmailForm = () => {
  const scriptURL = "https://script.google.com/macros/s/AKfycbwJy1eylKDIEwZg203RTA3lT886A-Gr06eVAy4QbGn-rIAuZOd6ngxfAKsbVtDMJXED0Q/exec";

  useEffect(() => {
    const form = document.forms['submit-to-google-sheet'];
    // const msgStatus = document.getElementById("statusMessage");
    let modal = document.getElementById("myModal");
    let span = document.getElementsByClassName("close")[0];

    const handleSubmit = (e) => {
      e.preventDefault();
      fetch(scriptURL, { method: 'POST', body: new FormData(form) })
        .then(response => {
          // Successful connection from google sheet triggers Modal display
          modal.style.display = "block";
          setTimeout(function () {
          }, 8000); // Timer for form reset | 8sec
          form.reset();
        })
        .catch(error => console.error('Error!', error.message));
    };

    // When the user clicks on <span> (x), close the modal
    span.onclick = function() {
        modal.style.display = "none";
    }
    
    // When the user clicks anywhere outside of the modal, close it
    window.onclick = function(event) {
        if (event.target === modal) {
        modal.style.display = "none";
        }
    }

    form.addEventListener('submit', handleSubmit);

    return () => {
      form.removeEventListener('submit', handleSubmit);
    };
  }, [scriptURL]);

  return (
    <div className="email-form">

      <form name="submit-to-google-sheet" action="">

        <input
          type="email"
          name="Email"
          placeholder="Enter your email here"
          required
        />
        <button type="submit" className="btn btn-primary btn-pos">Sign Up</button>

      </form>


      <div id="myModal" className="modal">

        {/* Modal content */}
        <div className="modal-content">

          <div className="modal-header">
            <span className="close">&times;</span>
            <h2>Confirmed!</h2>
          </div>

          <div className="modal-body">
            <p>Thank you for joining us on this journey!</p>
          </div>

        </div>

      </div>


    </div>
  );
};

export default EmailForm;
