import React from "react";

// Assets
import "./Newsletter.css";
import NewsletterBanner from "../../images/newsletter-banner.jpg";
import EmailForm from "../EmailForm/EmailForm";

const Newsletter = () => {
  return (
    <div className="Newsletter">

        <div className="news-content">
            <div className="left-n">
                <img src={NewsletterBanner} alt="Newsletter Banner" className="news-banner" />
            </div>

            <div className="right-news">
                <p className="news-text">Stay informed, inspired, and empowered</p>
                <h1 className="news-header">Receive expert insights, practical tips, and uplifting stories delivered straight to your inbox</h1>
                <p className="news-text join-text">Join our community of support and take proactive steps towards a happier, healthier you. Sign up today to start receiving valuable resources tailored to your mental health and well-being goals.</p>
                <EmailForm/>
            </div>
        </div>
    </div>
  )
}

export default Newsletter