import React from "react";
import { NavLink as Link } from "react-router-dom";

// Assets
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import BodyDysmorphicHero from "../../images/body-dysmorphic-banner.jpg";
import { FaCirclePlay } from "react-icons/fa6";
import heroBG from "../../images/img-bg.svg";
import "./BodyDysmorphicDisorder.css";

const BodyDysmorphicDisorder = () => {
  
  // Click event that scroll to the top of the page
  const handleClickScroll = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="BodyDysmorphicDisorder">
      <Header/>
      {/* Hero Section */}
      <div className="landHero">
        <div className="hero-left-side">
            <div className="hero-head-text no-justify">Body Dysmorphic Disorder</div>
            <p className="hero-text">
              Body dysmorphic disorder is a mental health condition characterized by an individual’s inability to stop thinking about defects or flaws in their looks. These defects or flaws may be perceived, real and minor and may not be perceived by others. Individuals with body dysmorphic appear convinced that there is something wrong with the way they look and they may appear uncomfortable being in social setting because they fear social scrutiny.  <br /> <br /> It is believed that people with this condition have greater brain activity than healthy controls in regions within a face-processing network on the left side of the brain for the low detail and the normal images.
            </p>
            <Link to="/contact-us" onClick={handleClickScroll}><div className="btn btn-secondry">Contact Us Now</div></Link>
        </div>
        <div className="hero-right-side">
            <img src={BodyDysmorphicHero} alt="banner" className="stack inner-hero lhero-banner" />
            <img src={heroBG} alt="Hero-BG" className="stack img-bg" />
        </div>
      </div>
      {/* end Hero Section */}

        {/* Types of Body dysmorphia */}
        <div className="Welcome" style={{alignItems: "flex-start"}}>
          <h1 className="welcome-header" style={{textAlign: "left"}}>Types of Body Dysmorphia</h1>
          <p className="sub-text" style={{textAlign: "left"}}><span>Body Dysmorphic Disorder by Proxy</span>: This is a type of body dysmorphia in which the individual’s concerns involved perceived imperfections with another person’s appearance. In order words, they have someone they intend to be like and strive to be like that person.</p>
          <p className="sub-text" style={{textAlign: "left"}}><span>Muscle Dysmorphia</span>: </p> 
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Muscle dysmorphia is a psychopathological condition and involves overly concerned with their muscularity and leanness. This is usually prevalent among males.</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">People with muscle dysmorphia believe that their bodies are small and weak although most people would perceive them be in body shape. They engage in more exercises to build their muscles.</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">People with body dysmorphia are so preoccupied with the thoughts of their body that it could affect their normal daily functioning including how they perceive themselves and others.</p></div>
        </div>         
        {/* end Types of Body dysmorphia */}

        {/* Symptoms of Body dysmorphia */}
        <div className="Welcome" style={{alignItems: "flex-start", marginTop: "-100px"}}>
          <h1 className="welcome-header" style={{textAlign: "left"}}>Symptoms of Body Dysmorphia</h1>

          <div className="treat-point" style={{textAlign: "left"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}}/><p className="point-text">Putting oversized clothing focused on hiding the perceived flaws</p></div>
          <div className="treat-point" style={{textAlign: "left"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}}/><p className="point-text">Engaging in too much exercise</p></div>
          <div className="treat-point" style={{textAlign: "left"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}}/><p className="point-text">Looking at the mirror or avoiding the mirror entirely</p></div>
          <div className="treat-point" style={{textAlign: "left"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}}/><p className="point-text">Constantly comparing themselves with others</p></div>
          <div className="treat-point" style={{textAlign: "left"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}}/><p className="point-text">Constantly asking people how they look</p></div>
          <div className="treat-point" style={{textAlign: "left"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}}/><p className="point-text">May adopt certain diet to suit how they want to look</p></div>
          <div className="treat-point" style={{textAlign: "left"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}}/><p className="point-text">Avoiding social interaction for fear of scrutiny</p></div>
          <Link to="/contact-us" onClick={handleClickScroll} style={{marginTop: "20px"}}><div className="btn btn-primary">Contact Us Now</div></Link>        
        </div>
        {/* end Symptoms of Body dysmorphia */}

        {/* Diagnosis */}
        <div className="Welcome" style={{alignItems: "flex-start", marginTop: "-90px"}}>
          <h1 className="welcome-header" style={{textAlign: "left", fontSize: "22px", fontWeight: 600}}>Diagnosis</h1>
          <p className="sub-text" style={{textAlign: "justify", marginTop: "-28px"}}>Diagnosis is usually made to ascertain the distress level caused by this condition. According to the Diagnostic and Statistical Manual of Mental Disorders, fifth edition), (DSM 5) diagnosis involves distress due to the perceived physical defect or flaw.</p>
        </div> 
        {/* end Diagnosis */}

        {/* Causes of Body dysmorphia */}
        <div className="Welcome" style={{alignItems: "flex-start", marginTop: "-90px"}}>
          <h1 className="welcome-header" style={{textAlign: "left", fontSize: "22px", fontWeight: 600}}>Causes of Body dysmorphia</h1>
          <p className="sub-text" style={{textAlign: "justify", marginTop: "-28px"}}>There is no clear known cause of body dysmorphia. However, they are risk factors to it. They include:</p>
          <div className="treat-point" style={{textAlign: "left"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}}/><p className="point-text">Genetics</p></div>
          <div className="treat-point" style={{textAlign: "left"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}}/><p className="point-text">Trauma: Traumatic events like verbal abuse or physical abuse could trigger it</p></div>
          <div className="treat-point" style={{textAlign: "left"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}}/><p className="point-text">Societal perception about body structure</p></div>
          <div className="treat-point" style={{textAlign: "left"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}}/><p className="point-text">Low self esteem</p></div>
          <div className="treat-point" style={{textAlign: "left"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}}/><p className="point-text">Fear of abandonment or feeling rejected</p></div>
          <div className="treat-point" style={{textAlign: "left"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}}/><p className="point-text">Social media projection of an ‘ideal body structure’</p></div>
          <div className="treat-point" style={{textAlign: "left"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}}/><p className="point-text">Perfectionism or comparing yourself with others</p></div>
          <div className="treat-point" style={{textAlign: "left"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}}/><p className="point-text">Comorbid with other mental issues like anxiety, depression and OCD</p></div>
        </div>         
        {/* Causes of Body dysmorphia */}

        {/* Treatment of body dysmorphia */}
        <div className="Welcome" style={{alignItems: "flex-start", marginTop: "-90px"}}>
          <h1 className="welcome-header" style={{textAlign: "left", fontSize: "22px", fontWeight: 600}}>Treatment of body dysmorphia</h1>
          <p className="sub-text" style={{textAlign: "justify", marginTop: "-28px"}}>A couple of therapies can help an individual manage body dysmorphia. They include:</p>
          <div className="treat-point" style={{textAlign: "left"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}}/><p className="point-text">Cognitive behavioural therapy (CBT)</p></div>
          <div className="treat-point" style={{textAlign: "left"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}}/><p className="point-text">Behavioural therapy</p></div>
        </div>         
        {/* end Treatment of body dysmorphia */}
      <Footer/>
    </div>
  )
}

export default BodyDysmorphicDisorder