import React from "react";
import { NavLink as Link } from "react-router-dom";

// Assets
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import DrugsAlcoholAddictionHero from "../../images/drugs-alcohol-addiction-hero.jpg";
import { FaCirclePlay } from "react-icons/fa6";
import heroBG from "../../images/img-bg.svg";
import "./DrugsAlcoholAddiction.css";

const DrugsAlcoholAddiction = () => {
  
  // Click event that scroll to the top of the page
  const handleClickScroll = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="DrugsAlcoholAddiction">
      <Header/>

      {/* Hero Section */}
      <div className="landHero">
        <div className="hero-left-side">
            <div className="hero-head-text no-justify">Drug and Alcohol Addiction</div>
            <p className="hero-text">
              Drug addiction otherwise known as substance use disorder, is a condition that affects a person’s brain and behaviour. It often leads to inability to control its use of a legal or illegal drug or medicine. Substances such as cocaine, alcohol, marijuana and nicotine also are psychoactive substances because they have the ability to alter an individual’s brain leading to distortion of thought and reality.
              <br /><br />Drug addiction can start with experimental use for recreational purpose mostly times in social environment. The risk of addiction is quite debilitating and some medicines such as opioid painkillers, have a higher risk and cause increased addiction more quickly than others.
            </p>
            <Link to="/contact-us" onClick={handleClickScroll}><div className="btn btn-secondry">Contact Us Now</div></Link>
        </div>
        <div className="hero-right-side">
            <img src={DrugsAlcoholAddictionHero} alt="banner" className="stack inner-hero lhero-banner" />
            <img src={heroBG} alt="Hero-BG" className="stack img-bg" />
        </div>
      </div>
      {/* end Hero Section */}

      {/* Symptoms */}
      <div className="Welcome" style={{alignItems: "flex-start"}}>
          <h1 className="welcome-header" style={{textAlign: "left"}}>Symptoms</h1>
          <p className="sub-text" style={{textAlign: "left", marginTop: "-20px", marginBottom: "10px"}}>Drug addiction symptoms or behaviors include, among others:</p>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Irresistible urge to use the drug regularly daily or even several times a day</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Over time, needing more of the drug to get the same effect</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Taking larger amounts of the drug over a longer period of time than intended</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Making certain that you maintain a supply of the drug</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Spending money on the drug, even though you can't afford it</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Borrowing or stealing money to fund use</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Disruption in activities as a result of drug use</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Continuous of the drug, even when it begins to interfere with your normal daily function, concentration, memory and relationship</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Engaging in risky activities such as driving, impulsive activities when under the influence of the drug</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Spending a good deal of time getting the drug, using the drug or recovering from the effects of the drug</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Failing in your attempts to stop using the drug</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Experiencing withdrawal symptoms when you attempt to stop taking the drug</p></div>
      </div>      
      {/* end Symptoms */}

      {/* Signs and symptoms of recent use can include */}
      <div className="Welcome" style={{alignItems: "flex-start", marginTop: "-100px"}}>
          <h1 className="welcome-header" style={{textAlign: "left", fontSize: "22px", fontWeight: 600, marginBottom: "10px"}}>Signs and symptoms of recent use can include</h1>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">A sense of euphoria or feeling "high"</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">A heightened sense of visual, auditory and taste perception</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Increased blood pressure and heart rate</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Red eyes</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Dry mouth</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Decreased coordination</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Difficulty concentrating or remembering</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Slowed reaction time</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Anxiety or paranoid thinking</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Finger coloration</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Major cravings for certain foods at unusual times</p></div>
      </div>       
      {/* end Signs and symptoms of recent use can include */}

      {/* Long-term use is often associated with */}
      <div className="Welcome" style={{alignItems: "flex-start", marginTop: "-100px"}}>
          <h1 className="welcome-header" style={{textAlign: "left", fontSize: "22px", fontWeight: 600, marginBottom: "10px"}}>Long-term use is often associated with:</h1>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Decreased mental sharpness</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Poor performance at school or at work</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">
          Ongoing cough and frequent lung infections</p></div>
          <Link to="/contact-us" onClick={handleClickScroll}><div className="btn btn-primary" style={{marginTop: "20px"}}>Contact Us Now</div></Link>
      </div>      
      {/* end Long-term use is often associated with */}

      {/* Diagnosis */}
      <div className="Welcome" style={{alignItems: "flex-start", marginTop: "-100px"}}>
          <h1 className="welcome-header" style={{textAlign: "left", fontSize: "22px", fontWeight: 600, marginBottom: "10px"}}>Diagnosis</h1>
          <p className="sub-text" style={{textAlign: "justify"}}>A single test can’t diagnose substance use disorder. Instead, healthcare providers rely on a thorough evaluation of the person’s medical history and behaviors surrounding substance use.</p>
      </div>       
      {/* end Diagnosis */}

      {/* Treatment of drug abuse and addiction */}
      <div className="Welcome" style={{alignItems: "flex-start", marginTop: "-140px"}}>
          <h1 className="welcome-header" style={{textAlign: "left", fontSize: "22px", fontWeight: 600, marginBottom: "10px" }}>Treatment of drug abuse and addiction</h1>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Psychotherapy</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Detoxification programme in a hospital or medical facility</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Medications</p></div>
      </div>
      {/* end Treatment of drug abuse and addiction */}

      <Footer/>
    </div>
  )
}

export default DrugsAlcoholAddiction;