import React from "react";
import { Helmet } from "react-helmet";

// Assets
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ContactUsContent from "../../components/ContactUsContent/ContactUsContent";

const ContactUs = () => {
  return (
    <div className="ContactUs">
      <Helmet>
        <title>Contact Us</title>
      </Helmet>
      <Header />
      <ContactUsContent />
      <Footer />
    </div>
  );
}

export default ContactUs;
