import React from "react"
// import { PopupButton } from "react-calendly";
import { NavLink as Link } from "react-router-dom";

// Assets
import "./ConditionsHero.css";
import ApproachHero from "../../images/approach-hero-banner.jpg";
import heroBG from "../../images/img-bg.svg";


const ConditionsHero = () => {

    // Click event that scroll to the top of the page
    const handleClickScroll = () => {
      window.scrollTo(0, 0);
    }; 
    
    return (
        <div className="landHero">
            <div className="hero-left-side">
              <div className="hero-head-text">
                Conditions
              </div>
              <p className="hero-text">
                The Unforsaken House of Hephzibah is dedicated to your mental wellness. Our team of experienced clinical psychologists offers personalized care tailored to your needs. Whether you're navigating anxiety, depression, or seeking personal growth, we're here to support you every step of the way. Explore our services and meet our compassionate team. Your journey to healing starts here.
              </p>
              <Link to="/book-session"  onClick={handleClickScroll}><div className="btn btn-secondry">Book a Session</div></Link>
              {/* <PopupButton
                url="https://calendly.com/theunforsaken"
                rootElement={document.getElementById("root")}
                text="Book a Session"
                className="btn btn-secondry"
                styles={{whiteSpace: "nowrap", width: "180px"}}
              /> */}
            </div>
            <div className="hero-right-side">
              <img src={ApproachHero} alt="About us Hero banner" className="stack inner-hero" />
              <img src={heroBG} alt="Hero-BG" className="stack img-bg" />
            </div>
        </div>
      )
}

export default ConditionsHero;