import React from "react";
import { NavLink as Link } from "react-router-dom";

// Assets
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import BorderlinePersonalityHero from "../../images/borderline-personality-disorder-hero.jpg";
import { FaCirclePlay } from "react-icons/fa6";
import heroBG from "../../images/img-bg.svg";
import "./BorderlinePersonalityDisorder.css";

const BorderlinePersonalityDisorder = () => {
  
  // Click event that scroll to the top of the page
  const handleClickScroll = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="BorderlinePersonalityDisorder">
      <Header/>
      {/* Hero Section */}
      <div className="landHero">
        <div className="hero-left-side">
            <div className="hero-head-text no-justify">Borderline Personality Disorder</div>
            <p className="hero-text">
              Borderline personality disorder is a mental illness that is characterised by unstable moods, behaviour and relationships. It severely impacts a person’s ability to regulate their emotions. This loss of emotional control can increase impulsivity, affect how a person feels about themselves, and negatively impact their relationships with others.
            </p>
            <Link to="/contact-us" onClick={handleClickScroll}><div className="btn btn-secondry">Contact Us Now</div></Link>
        </div>
        <div className="hero-right-side">
            <img src={BorderlinePersonalityHero} alt="banner" className="stack inner-hero lhero-banner" />
            <img src={heroBG} alt="Hero-BG" className="stack img-bg" />
        </div>
      </div>
      {/* end Hero Section */}

      {/* Symptoms */}
      <div className="Welcome" style={{alignItems: "flex-start"}}>
          <h1 className="welcome-header" style={{textAlign: "left"}}>Symptoms</h1>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Explosive emotions</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Fear of abandonment</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Unstable relationships</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Impulsive, self-destructive behaviours.</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Self-harm</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Extreme emotional swings</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Blurred or shifting self-image</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Chronic feelings of emptiness</p></div>
        </div>      
      {/* end Symptoms */}

      {/* Causes of BPD */}
      <div className="Welcome" style={{alignItems: "flex-start", marginTop: "-90px"}}>
          <h1 className="welcome-header" style={{textAlign: "left", fontSize: "22px", fontWeight: 600}}>Causes of BPD</h1>
          <p className="sub-text" style={{textAlign: "justify", marginTop: "-28px"}}>There are couple of environmental factors that can trigger an onset of BPD. They include: stress, trauma, abuse, adverse childhood experiences.</p>
          <Link to="/contact-us" onClick={handleClickScroll}><div className="btn btn-primary">Contact Us Now</div></Link>
        </div>      
      {/* end Causes of BPD */}

      {/* Diagnosis of Borderline Personality disorder */}
      <div className="Welcome" style={{alignItems: "flex-start", marginTop: "-90px"}}>
          <h1 className="welcome-header" style={{textAlign: "left", fontSize: "22px", fontWeight: 600}}>Diagnosis of Borderline Personality disorder</h1>
          <p className="sub-text" style={{textAlign: "justify", marginTop: "-28px"}}>Diagnosis is usually made when the following is affected:</p>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Cognition</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Affect</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">interpersonal functioning</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">impulse control</p></div>
        </div>       
      {/* end Diagnosis of Borderline Personality disorder */}

      {/* Treatment of BPD */}
      <div className="Welcome" style={{alignItems: "flex-start", marginTop: "-90px"}}>
        <h1 className="welcome-header" style={{textAlign: "left", fontSize: "22px", fontWeight: 600}}>Treatment of BPD</h1>
        <p className="sub-text" style={{textAlign: "justify"}}><span>Psychotherapy</span>: This is a fundamental treatment approach for borderline personality disorder. The therapist will focus on the individual’s current ability to function, teach how to manage emotions that feel uncomfortable. The person will also learn to reduce their impulsiveness by helping them observe feelings rather than acting on them. Other form therapies include: dialectical behavior therapy (DBT), schema-focused therapy, mentalization-based therapy (MBT), transference-focused psychotherapy (TFP).</p>
        <p className="sub-text" style={{textAlign: "justify"}}><span>Medications</span>: Medications will also help the individual as they learn to maange their emotions and relationships.</p>
      </div>
      {/* end Treatment of BPD */}
      <Footer/>
    </div>
  )
}

export default BorderlinePersonalityDisorder;