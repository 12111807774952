import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion } from "framer-motion";
// import { PopupButton } from 'react-calendly';
import { NavLink as Link } from "react-router-dom";

// Assets
import "./History.css";
import Arror from "../../images/arrow.svg";
import Therapist from "../../images/history-therapist-img.png";


//counter function
const CountUp = ({ end, duration }) => {

    const [count, setCount] = useState(0);
  
    useEffect(() => {
      let start = 0;
      const incrementTime = (duration / end) * 1000; // duration in seconds to milliseconds
  
      const timer = setInterval(() => {
        start += 1;
        setCount(start);
        if (start >= end) clearInterval(timer);
      }, incrementTime);
  
      return () => clearInterval(timer);
    }, [end, duration]);
  
    return <span>{count}+</span>;
  };

//   Motion Animation
  const transition = { type: "spring", duration: 3 };

const History = () => {
    // Click event that scroll to the top of the page
    const handleClickScroll = () => {
        window.scrollTo(0, 0);
    }; 

    const { ref: firstSessionRef, inView: firstSessionInView } = useInView();
    const { ref: secondSessionRef, inView: secondSessionInView } = useInView();
    const { ref: thirdSessionRef, inView: thirdSessionInView } = useInView();
    const { ref: fourthSessionRef, inView: fourthSessionInView } = useInView();
  return (
    <div className="History">
        <div className="scroll">
            <motion.p 
                className="scroll-text"
                transition={transition}
                initial={{ top: "30px" }}
                whileInView={{ top: "0px" }}
            >
                Scroll Down
            </motion.p>
            <motion.img 
                src={Arror} 
                alt="" className="s-down"
                transition={transition}
                initial={{ bottom: "30px" }}
                whileInView={{ bottom: "0px" }}
                loading="lazy"
            />
        </div>

        <div className="l-side">
            <p className="h-text">We are a professional and fully registered faith-based private Psychological facility. </p>
            <motion.img 
                src={Therapist} 
                alt="Therapist" className="history-img"
                loading="lazy"
                transition={transition}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
            />
        </div>

        <div className="r-side">
            <p className="text">We employ a non-invasive, empathetic, and friendly approach with all our clients. Our team is focused and has a strong desire to achieve.</p>

            <Link to="/book-session"  onClick={handleClickScroll}><div className="btn btn-secondry">Book a Session</div></Link>
            {/* <PopupButton
                url="https://calendly.com/theunforsaken"
                rootElement={document.getElementById("root")}
                text="Book a Session"
                className="btn btn-secondry"
                styles={{whiteSpace: "nowrap", width: "180px"}}
            /> */}

            <div className="sessions">
                {/* Sessions */}
                <div className="session">
                    <h1 className="session-header" ref={firstSessionRef}>
                        {firstSessionInView ? <CountUp end={150} duration={2} /> : '0+'}
                    </h1>
                    <p className="s-text">SESSIONS</p>
                    <h2 className="session-title">Group Theraphy</h2>
                </div>
                
                <div className="session">
                    <h1 className="session-header" ref={secondSessionRef}>
                        {secondSessionInView ? <CountUp end={300} duration={2} /> : '0+'}
                    </h1>
                    <p className="s-text">SESSIONS</p>
                    <h2 className="session-title">Psychiatric Evaluation</h2>
                </div>
                <div className="session">
                    <h1 className="session-header" ref={thirdSessionRef}>
                        {thirdSessionInView ? <CountUp end={136} duration={2} /> : '0+'}
                    </h1>
                    <p className="s-text">SESSIONS</p>
                    <h2 className="session-title">EAP Services</h2>
                </div>
                <div className="session">
                    <h1 className="session-header" ref={fourthSessionRef}>
                        {fourthSessionInView ? <CountUp end={100} duration={2} /> : '0 +'}
                    </h1>
                    <p className="s-text">SESSIONS</p>
                    <h2 className="session-title">Addiction Management</h2>
                </div>

            </div>
        </div>
    </div>
  )
}

export default History;