import React from "react";
import { NavLink as Link } from "react-router-dom";

// Assets
import "./Success.css";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import SuccessBannerHero from "../../images/message-sent.jpg";
import heroBG from "../../images/img-bg.svg";

const Success = () => {
  
  // Click event that scroll to the top of the page
  const handleClickScroll = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="Success">
        <Header/>
        {/* Hero Section */}
        <div className="landHero">
          <div className="hero-left-side">
              <div className="hero-head-text no-justify">Success</div>
              <p className="hero-text">
                Your message has been sent and a response would be sent within three business days
              </p>
              <Link to="/contact-us" onClick={handleClickScroll}><div className="btn btn-secondry">Back to Home Page </div></Link>
          </div>
          <div className="hero-right-side">
              <img src={SuccessBannerHero} alt="banner" className="stack inner-hero lhero-banner" />
              <img src={heroBG} alt="Hero-BG" className="stack img-bg" />
          </div>
        </div>
        {/* end Hero Section */}            
        <Footer/>
    </div>
  )
}

export default Success;