import React from "react";

// Assets
import "./InitialConsultPage.css";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import InitialConsultsForm from "../../components/InitialConsultsForm/InitialConsultsForm";

const InitialConsultPage = () => {
  return (
    <div className="InitialConsultPage">
        <Header/>
            <InitialConsultsForm/>
        <Footer/>
    </div>
  )
}

export default InitialConsultPage;