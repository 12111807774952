import React from "react";
import { NavLink as Link } from "react-router-dom";

// Assets
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import NarcissisticPersonalityDisorderHero from "../../images/narcissistic-personality-hero.jpg";
import { FaCirclePlay } from "react-icons/fa6";
import heroBG from "../../images/img-bg.svg";
import "./NarcissisticPersonalityDisorder.css";

const NarcissisticPersonalityDisorder = () => {
  
  // Click event that scroll to the top of the page
  const handleClickScroll = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="NarcissisticPersonalityDisorder">
      <Header/>

      {/* Hero Section */}
      <div className="landHero">
        <div className="hero-left-side">
            <div className="hero-head-text no-justify">Narcissistic Personality Disorder</div>
            <p className="hero-text">
              Narcissistic personality disorder is a mental health condition that involves when people have an unreasonably high sense of their own importance. They need and seek too much attention and want people to admire them. People with this condition lack empathy. People with narcissistic personality may become impatient or angry when they don’t receive special recognition or treatment, have major problems interacting with others and easily feel slighted, Experience major problems dealing with stress and adapting to change, or withdraw from or avoid situations in which they might fail, or react with rage or contempt and try as a failure.
            </p>
            <Link to="/contact-us" onClick={handleClickScroll}><div className="btn btn-secondry">Contact Us Now</div></Link>
        </div>
        <div className="hero-right-side">
            <img src={NarcissisticPersonalityDisorderHero} alt="banner" className="stack inner-hero lhero-banner" />
            <img src={heroBG} alt="Hero-BG" className="stack img-bg" />
        </div>
      </div>
      {/* end Hero Section */}

      {/* Different types of narcissist */}
      <div className="Welcome" style={{alignItems: "flex-start"}}>
        <h1 className="welcome-header" style={{textAlign: "left"}}>Different Types of Narcissist</h1>
        <p className="sub-text" style={{textAlign: "justify"}}>
          Narcissism comes in several forms. Adaptive narcissism is when a person with this disorder leans into positive traits—such as self-sufficiency and confidence—that can actually be healthy. They may help someone set high ambitions at work, for example, or enjoy satisfying relationships without being over-dependent on a partner.
          <br /><br />
          Maladaptive narcissism, on the other hand, is characterized by the toxic traits, such as a sense of entitlement and willingness to exploit others.
        </p>
        <h1 className="welcome-header" style={{textAlign: "left", fontSize: "22px", fontWeight: 600, marginTop: "30px", marginBottom: "4px"}}>Diagnosis of Narcissistic Personality Disorder</h1>
        <p className="sub-text" style={{textAlign: "justify", marginBottom: "12px"}}>Some symptoms of narcissistic personality disorder overlap with other personality disorders. This could affect proper diagnosis.</p>


        <h1 className="welcome-header" style={{textAlign: "left", fontSize: "22px", fontWeight: 600, marginBottom: "4px"}}>Diagnosis of this condition is usually:</h1>
        <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">A thorough psychological evaluation that may include filling out questionnaires</p></div>
        <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Guidelines in the Diagnostic and Statistical Manual of Mental Disorders (DSM-5), published by the American Psychiatric Association.</p></div>
        <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">The severity of the symptoms</p></div>
        <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">How the symptoms negatively affect your normal daily functioning</p></div>


        <h1 className="welcome-header" style={{textAlign: "left", fontSize: "22px", fontWeight: 600, marginTop: "30px", marginBottom: "4px"}}>Symptoms of narcissistic personality disorder</h1>
        <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Feel that they deserve privileges and special treatment</p></div>
        <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Have an unreasonably high sense of self-importance and require constant, excessive admiration</p></div>
        <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Expect to be recognized as superior even without achievements</p></div>
        <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Make achievements and talents seem bigger than they are</p></div>
        <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Be preoccupied with fantasies about success, power, brilliance, beauty or the perfect mate</p></div>
        <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Be critical of and look down on people they feel are not important</p></div>
        <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Expect special favors and expect other people to do what they want without questioning them</p></div>
        <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Take advantage of others to get what they want.</p></div>
        <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Have an inability or unwillingness to recognize the needs and feelings of others</p></div>
        <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Be envious of others and believe others envy them</p></div>
        <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Believe they are superior to others and can only spend time with or be understood by equally special people</p></div>
        <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Behave in an arrogant way, brag a lot and come across as conceited</p></div>
        <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Insist on having the best of everything for instance, the best car or office</p></div>


        <h1 className="welcome-header" style={{textAlign: "left", fontSize: "22px", fontWeight: 600, marginTop: "30px", marginBottom: "4px"}}>Treatment</h1>
        <p className="sub-text" style={{textAlign: "justify", marginBottom: "4px"}}>A number of therapy will help the individual cope with the condition. They include:</p>
        <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Support group</p></div>
        <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Counseling psychology</p></div>
        <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Family therapy</p></div>
        <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Group psychotherapy</p></div>
      </div>
      {/* end Different types of narcissist */}

      <Footer/>
    </div>
  )
}

export default NarcissisticPersonalityDisorder;