import React from "react";

// Assets
import "./AboutUs.css";
import AboutHero from "../../components/AboutHero/AboutHero";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import AboustUsWelcome from "../../components/AboustUsWelcome/AboustUsWelcome";
import Seperator from "../../components/Seperator/Seperator";
import UsDifferent from "../../components/UsDifferent/UsDifferent";
import CoreValues from "../../components/CoreValues/CoreValues";
import OurTeam from "../../components/OurTeam/OurTeam";


const AboutUs = () => {
  return (
    <div className="About-us">
        <Header/>
        <AboutHero/>
        <AboustUsWelcome/>
        <Seperator/>
        <UsDifferent/>
        <CoreValues/>
        <OurTeam/>
        <Footer/>
    </div>
  )
}

export default AboutUs;