import React from "react";
import { NavLink as Link } from "react-router-dom";

// Assets
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import AngerIssuesBanner from "../../images/anger-issues-banner.jpg";
import heroBG from "../../images/img-bg.svg";
import "./AngerIssues.css";
import { FaCirclePlay } from "react-icons/fa6";

const AngerIssues = () => {

  // Click event that scroll to the top of the page
  const handleClickScroll = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="AngerIssues">
        <Header/>

        {/* Hero Section */}
        <div className="landHero">
            <div className="hero-left-side">
                <div className="hero-head-text no-justify">Anger Issues</div>
                <p className="hero-text">
                    Anger is a normal human emotion. People at some point in time finds themselves in situation they lose their temper. However, when anger becomes recurring and begins to interfere with your relationship with people and it also affects your normal daily functioning, then it has become clinical significant to seek professional help. 
                </p>
                <Link to="/contact-us" onClick={handleClickScroll}><div className="btn btn-secondry">Contact Us Now</div></Link>
            </div>
            <div className="hero-right-side">
                <img src={AngerIssuesBanner} alt="banner" className="stack inner-hero lhero-banner" />
                <img src={heroBG} alt="Hero-BG" className="stack img-bg" />
            </div>
        </div>
        {/* end Hero Section */}

        {/* Types of Anger */}
        <div className="Welcome">
            <p className="sub-text">Anger management is an approach that intends to help the individual manage their anger outburst in a healthy way. It is not a bad thing to feel angry but it becomes a problem when an individual is not able to channel their anger in a healthy way or constructive way. When anger becomes destructive and disruptive, then it is time to seek professional help.</p>
            <br /><br />
            <h1 className="welcome-header">Types of Anger</h1>
            <p className="sub-text" style={{textAlign: "left"}}><span>Inward anger</span>: this is anger towards the self. It involves self-punishing, self-criticism, and getting into self-destructive thoughts or action.</p>
            <p className="sub-text" style={{textAlign: "left"}}><span>Outward anger</span>: this is anger toward other people or things. It could involve getting violent with people or destroying things.</p>
            <p className="sub-text" style={{textAlign: "left"}}><span>Passive anger</span>: This can include actions like stone-walling people, silent treatment, making condescending or demeaning comments about people mostly in their absence.</p>
            </div>

            <div className="Welcome" style={{alignItems: "flex-start"}}>
                <h1 className="welcome-header" style={{textAlign: "left"}}>Signs that you may have anger issues</h1>
                <div className="treat-point" style={{textAlign: "left"}}><FaCirclePlay className="point-icon" /><p className="point-text">Constantly finding yourself annoyed or angry</p></div>
                <div className="treat-point" style={{textAlign: "left"}}><FaCirclePlay className="point-icon" /><p className="point-text">Are hurting others either verbally or physically</p></div>
                <div className="treat-point" style={{textAlign: "left"}}><FaCirclePlay className="point-icon" /><p className="point-text">Regret after you have done something wrong</p></div>
                <div className="treat-point" style={{textAlign: "left"}}><FaCirclePlay className="point-icon" /><p className="point-text">Getting easily irritable over trivial things</p></div>
                <div className="treat-point" style={{textAlign: "left"}}><FaCirclePlay className="point-icon" /><p className="point-text">Feeling out of control when angry</p></div>
            </div>

            <div className="Welcome" style={{alignItems: "flex-start"}}>
                <h1 className="welcome-header" style={{textAlign: "left"}}>Anger Management Treatment</h1>
                <p className="sub-text" style={{textAlign: "left"}}><span>Cognitive Behavioral Therapy (CBT)</span>: CBT is often the treatment of choice for anger management. It helps in addressing thought distortions that trigger anger. CBT will help the individual challenges those thoughts and be more control of his/her emotions.</p>
                <p className="sub-text" style={{textAlign: "left"}}><span>Psychodynamic Therapy</span>: This therapy address how the past experiences may have impacted on an individual’s pattern of thinking associated with anger. It also focuses on different situations that trigger anger outburst or anger expression.</p>
                <p className="sub-text" style={{textAlign: "left"}}><span>Dialectical Behavioral Therapy (DBT)</span>: DBT uses a combination of cognitive and behavioral techniques to help people learn how to identify and change thoughts that trigger anger. It also helps them to learn new adaptive ways to cope with anger. It improves resilience, mindfulness, emotional regulation and communication.</p>
                <p className="sub-text" style={{textAlign: "left"}}><span>Family Therapy</span>: this is usually helpful if the if the anger is target at family members. The therapist will set up a group session with family members of the individual and they would work out ways to foster healthier communication and relationship. This is also geared towards reducing anger triggers and adaptive coping mechanisms.</p>
                <br />
                <Link to="/contact-us" onClick={handleClickScroll}><div className="btn btn-secondry">Contact Us Now</div></Link>
            </div>
            
                 
        {/* end Types of Anger */}
        <Footer/>
    </div>
  )
}

export default AngerIssues