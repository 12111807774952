import React from "react";
import { NavLink as Link } from "react-router-dom";

// Assets
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import BipolarDisorderHero from "../../images/bipolar-disorder-hero-banner.jpg";
// import { FaCirclePlay } from "react-icons/fa6";
import heroBG from "../../images/img-bg.svg";
import "./BipolarDisorder.css";

const BipolarDisorder = () => {
  
  // Click event that scroll to the top of the page
  const handleClickScroll = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="BipolarDisorder">
      <Header/>
      {/* Hero Section */}
      <div className="landHero">
        <div className="hero-left-side">
            <div className="hero-head-text no-justify">Bipolar Disorder</div>
            <p className="hero-text">
              Bipolar disorder is a mental illness characterized by unusual shift in an individual’s mood, energy and activities. These shifts can impair an individual’s normal daily functioning. It causes extreme mood swings that include emotional highs (mania or hypomania) and lows (depression).
            </p>
            <Link to="/contact-us" onClick={handleClickScroll}><div className="btn btn-secondry">Contact Us Now</div></Link>
        </div>
        <div className="hero-right-side">
            <img src={BipolarDisorderHero} alt="banner" className="stack inner-hero lhero-banner" />
            <img src={heroBG} alt="Hero-BG" className="stack img-bg" />
        </div>
        </div>
        {/* end Hero Section */}

        {/* Types of bipolar disorder */}
        <div className="Welcome" style={{alignItems: "flex-start"}}>
          <h1 className="welcome-header" style={{textAlign: "left"}}>Types of Bipolar Disorder</h1>
          <p className="sub-text" style={{textAlign: "left"}}><span>Bipolar I</span>: with extreme, long-lasting highs (mania) as well as depressive episodes. It may also include psychosis (difficulty knowing what is real or not).</p>
          <p className="sub-text" style={{textAlign: "left"}}><span>Bipolar II</span>: with highs that are less extreme (hypomania) that only last or a few hours or days, as well as depressive episodes. It will also include periods of normal mood.</p>
        </div>  
        {/* end Types of bipolar disorder */}

        {/* Bipolar involves two different episodes */}
        <div className="Welcome" style={{alignItems: "flex-start", marginTop: "-110px"}}>
          <h1 className="welcome-header" style={{textAlign: "left", fontSize: "22px", fontWeight: 600}}>Bipolar involves two different episodes</h1>
          <p className="sub-text" style={{textAlign: "left"}}><span>Manic episode</span>: Manic episodes may include symptoms such as high energy, reduced need for sleep and loss of touch with reality.</p>
          <p className="sub-text" style={{textAlign: "left"}}><span>Depressive episode</span>: Depressive episodes may include symptoms such as low energy, low motivation and loss of interest in daily activities. Mood episodes last days to months at a time and may also be associated with suicidal thoughts.</p>
          <Link to="/contact-us" onClick={handleClickScroll} style={{marginTop: "20px"}}><div className="btn btn-secondry">Contact Us Now</div></Link>
        </div>      
        {/* end Bipolar involves two different episodes */}
      <Footer/>
    </div>
  )
}

export default BipolarDisorder