import React from "react";
import { NavLink as Link } from "react-router-dom";

import "./Blog.css";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import BlogHero from "../../images/blog-hero.jpg";
import heroBG from "../../images/img-bg.svg";

const Blog = () => {
  
  // Click event that scroll to the top of the page
  const handleClickScroll = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="Blog">
      <Header/>

      {/* Hero Section */}
      <div className="landHero">
        <div className="hero-left-side">
            <div className="hero-head-text no-justify">Blog</div>
            <p className="hero-text">
              Understanding and Navigating Mental Health: Insights and Strategies from Our Experts
            </p>
            <Link to="/contact-us" onClick={handleClickScroll}><div className="btn btn-secondry">Contact Us Now</div></Link>
        </div>
        <div className="hero-right-side">
            <img src={BlogHero} alt="banner" className="stack inner-hero lhero-banner" />
            <img src={heroBG} alt="Hero-BG" className="stack img-bg" />
        </div>
      </div>
      {/* end Hero Section */}

      <Footer/>
    </div>
  )
}

export default Blog;