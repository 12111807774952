import React from "react";
// import { NavLink as Link } from "react-router-dom";

// Assets
import "./BookSession.css";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import BookSessionBanner from "../../images/book-session-banner.jpg";
import heroBG from "../../images/img-bg.svg";
import PricingTable from "../../components/PricingTable/PricingTable";

const BookSession = () => {

  // Click event that scroll to the top of the page
//   const handleClickScroll = () => {
//     window.scrollTo(0, 0);
//   };

  return (
    <div className="BookSession">
        <Header/>

        {/* Hero Section */}
        <div className="landHero">
            <div className="hero-left-side">
                <div className="hero-head-text no-justify">Book a Session</div>
                <p className="hero-text">
                    Transparent and Affordable Care: Our Pricing for Mental Health Services
                </p>
                <div className="hero-ctas" style={{display: "flex", gap: "20px"}}>
                    {/* <Link to="/initial-consult" onClick={handleClickScroll}><div className="btn btn-primary">Initial Consult</div></Link> */}
                    <a href="#prices"><div className="btn btn-secondry">View Prices</div></a>
                </div>
            </div>
            <div className="hero-right-side">
                <img src={BookSessionBanner} alt="banner" className="stack inner-hero lhero-banner" />
                <img src={heroBG} alt="Hero-BG" className="stack img-bg" />
            </div>
        </div>
        {/* end Hero Section */}
        <PricingTable />

        <Footer/>
    </div>
  )
}

export default BookSession