import React from "react";
import { NavLink as Link } from "react-router-dom";

// Assets
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { FaCirclePlay } from "react-icons/fa6";
import AutismHero from "../../images/autism-banner.jpg";
import heroBG from "../../images/img-bg.svg";

const Autism = () => {
  
  // Click event that scroll to the top of the page
  const handleClickScroll = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="Autism">
      <Header/>

        {/* Hero Section */}
        <div className="landHero">
            <div className="hero-left-side">
                <div className="hero-head-text no-justify">Autism</div>
                <p className="hero-text">
                  Autism spectrum disorder (ASD) is developmental disability caused by differences in the brain activity, which affects the central nervous system. It is characterized by repetitive or restrictive behaviours and difficulty with social communication and interaction, and restricted or repetitive behaviors or interests. People with ASD have different ways they learn, communicate and understand situations.
                </p>
                <Link to="/contact-us" onClick={handleClickScroll}><div className="btn btn-secondry">Contact Us Now</div></Link>
            </div>
            <div className="hero-right-side">
                <img src={AutismHero} alt="banner" className="stack inner-hero lhero-banner" />
                <img src={heroBG} alt="Hero-BG" className="stack img-bg" />
            </div>
        </div>
        {/* end Hero Section */}   

        {/* Major signs of autism include */}
        <div className="Welcome" style={{alignItems: "flex-start"}}>
          <h1 className="welcome-header" style={{textAlign: "left"}}>Major signs of autism include</h1>
          <div className="treat-point" style={{textAlign: "left"}}><FaCirclePlay className="point-icon" /><p className="point-text">Delayed language skills</p></div>
          <div className="treat-point" style={{textAlign: "left"}}><FaCirclePlay className="point-icon" /><p className="point-text">Delayed movement skills</p></div>
          <div className="treat-point" style={{textAlign: "left"}}><FaCirclePlay className="point-icon" /><p className="point-text">Delayed cognitive or learning skills</p></div>
          <div className="treat-point" style={{textAlign: "left"}}><FaCirclePlay className="point-icon" /><p className="point-text">Unusual mood or emotional reactions. (Temper tantrums)</p></div>
          <div className="treat-point" style={{textAlign: "left"}}><FaCirclePlay className="point-icon" /><p className="point-text">Hyperactive, impulsive, and/or inattentive behavior</p></div>
          <div className="treat-point" style={{textAlign: "left"}}><FaCirclePlay className="point-icon" /><p className="point-text">Unusual eating and sleeping habits.</p></div>
          <div className="treat-point" style={{textAlign: "left"}}><FaCirclePlay className="point-icon" /><p className="point-text">Gastrointestinal issues (for example, constipation)</p></div>
        </div>           
        {/* end Major signs of autism include */}

        <div className="Welcome" style={{alignItems: "flex-start"}}>
          <h1 className="welcome-header" style={{textAlign: "left"}}>Treatment of autism</h1>
          <p className="sub-text" style={{textAlign: "left"}}>There is no particular standard treatment for autism spectrum disorder (ASD). However, there are different ways to help minimize the symptoms and improve abilities of individual with ASD.</p>
          <p className="sub-text" style={{textAlign: "left"}}>People of all ages, at all levels of ability, can often improve their lives with individualized treatment and intervention plans. The following intervention can help in management of autism.</p>
          <br />
          <Link to="/contact-us" onClick={handleClickScroll}><div className="btn btn-primary">Contact Us Now</div></Link>
        </div>
      <Footer/>
    </div>
  )
}

export default Autism