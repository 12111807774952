import React from "react";
import { NavLink as Link } from "react-router-dom";

import "./ErrorPage.css";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ErrorBannerHero from "../../images/error-hero.jpg";
import heroBG from "../../images/img-bg.svg";

const ErrorPage = () => {
  
  // Click event that scroll to the top of the page
  const handleClickScroll = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="ErrorPage">
      <Header/>

        {/* Hero Section */}
        <div className="landHero">
          <div className="hero-left-side">
              <div className="hero-head-text no-justify">404 Error</div>
              <p className="hero-text">
                The resource you seek has taken you clear off the path
              </p>
              <Link to="/" onClick={handleClickScroll}><div className="btn btn-secondry">Back to Home Page </div></Link>
          </div>
          <div className="hero-right-side">
              <img src={ErrorBannerHero} alt="banner" className="stack inner-hero lhero-banner" />
              <img src={heroBG} alt="Hero-BG" className="stack img-bg" />
          </div>
        </div>
        {/* end Hero Section */}  

      <Footer/>
    </div>
  )
}

export default ErrorPage