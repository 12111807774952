import React from "react";

// Assets
import "./OurApproach.css"
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ApproachHero from "../../components/ApproachHero/ApproachHero";
import ApproachWelcome from "../../components/ApproachWelcome/ApproachWelcome";
import PersonalHistory from "../../components/PersonalHistory/PersonalHistory";
import Neurological from "../../components/Neurological/Neurological";
import TreatmentPlan from "../../components/TreatmentPlan/TreatmentPlan";
import Feedback from "../../components/Feedback/Feedback";
import FeedbackTwo from "../../components/FeedbackTwo/FeedbackTwo";

const OurApproach = () => {
  return (
    <div className="OurApproach">
        <Header/>
        <ApproachHero/>
        <ApproachWelcome/>
        <PersonalHistory/>
        <Neurological/>
        <TreatmentPlan/>
        <Feedback/>
        <FeedbackTwo/>
        <Footer/>
    </div>
  )
}

export default OurApproach