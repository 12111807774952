import React from "react";
// import { PopupButton } from "react-calendly";
import { NavLink as Link } from "react-router-dom";

// Assets
import "./Welcome.css";

const Welcome = () => {
    // Click event that scroll to the top of the page
    const handleClickScroll = () => {
      window.scrollTo(0, 0);
    }; 
    
  return (
    <div className="Welcome">
      <h1 className="welcome-header">At <span className="highlight">the Unforsaken House</span>, we're dedicated to your mental wellness</h1>
      <p className="sub-text">Our team of experienced clinical psychologists offers personalized care tailored to your needs. Whether you're navigating anxiety, depression, or seeking personal growth, we're here to support you every step of the way. Explore our services and meet our compassionate team. Your journey to healing starts here.</p>
      <Link to="/book-session"  onClick={handleClickScroll}><div className="btn btn-primary">Book a Session</div></Link>
      {/* <PopupButton
        url="https://calendly.com/theunforsaken"
        rootElement={document.getElementById("root")}
        text="Book a Session"
        className="btn btn-primary"
        // styles={{whiteSpace: "nowrap", width: "180px"}}
      /> */}
    </div>
  )
}

export default Welcome;