import React from "react";
import { NavLink as Link } from "react-router-dom";

// Assets
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import DepressionHero from "../../images/depression-hero.jpg";
import { FaCirclePlay } from "react-icons/fa6";
import heroBG from "../../images/img-bg.svg";
import "./Depression.css";

const Depression = () => {
  
  // Click event that scroll to the top of the page
  const handleClickScroll = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="Depression">
      <Header/>
      {/* Hero Section */}
      <div className="landHero">
        <div className="hero-left-side">
            <div className="hero-head-text no-justify">Depression</div>
            <p className="hero-text">
              Depression is one of the commonest mental health conditions. It often presents with a low mood, sense of hopelessness, helplessness, loss of interest in previously enjoyed activity, changes in relationship, social withdrawal.  Depression can affect how an individual appraises events and situations. Oftentimes people with depression tend to see things in the negative. Depression range from spectrum of mild to severe levels. The most severe form of depression is clinical depression.
            </p>
            <Link to="/contact-us" onClick={handleClickScroll}><div className="btn btn-secondry">Contact Us Now</div></Link>
        </div>
        <div className="hero-right-side">
            <img src={DepressionHero} alt="banner" className="stack inner-hero lhero-banner" />
            <img src={heroBG} alt="Hero-BG" className="stack img-bg" />
        </div>
      </div>
      {/* end Hero Section */}  

      {/* Signs and symptoms */}
      <div className="Welcome" style={{alignItems: "flex-start"}}>
          <h1 className="welcome-header" style={{textAlign: "left"}}>Signs and Symptoms</h1>
          <p className="sub-text" style={{textAlign: "justify", marginTop: "-28px"}}>Depression manifests in different ways for different people. However, the following are the common sign of depression. When the symptoms last for more than 2 weeks, it is important the individual seek the services of a psychologist. 5 or more symptoms have to be present for a diagnosis to be made. continuous low mood or sadness</p>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Low mood</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Sense of hopelessness and helplessness</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Cry spells</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Changes in sleep (sleeping too much or too little)</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Weight changes (weight gain or weight loss)</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Low self-esteem</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">anger, guilt or impatient</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Loss of interest or lack of motivation in previously enjoyable activities</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Difficulty taking decisions</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Self-harm/self-injury</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Suicide ideation</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Low energy</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Low libido</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Changes in movement (Moving too slowly or sluggishly)</p></div>
          <Link to="/contact-us" onClick={handleClickScroll}><div className="btn btn-primary" style={{marginTop: "20px"}}>Contact Us Now</div></Link>
        </div>      
      {/* end Signs and symptoms */}

      {/* Diagnosis and treatment */}
      <div className="Welcome" style={{alignItems: "flex-start", marginTop: "-90px"}}>
        <h1 className="welcome-header" style={{textAlign: "left", fontSize: "22px", fontWeight: 600}}>Diagnosis and Treatment</h1>
        <p className="sub-text" style={{textAlign: "justify"}}><span>Treatment</span>: Treatment plan varies across individuals. Diagnosis of depression is usually made when an individual has 5 of the aforementioned symptoms spanning for a period of 2 weeks. Severe level of depression will require combination of medication (anti-depressants) and talk therapy for better management. Cognitive Behaviour therapy (CBT) will also be used in the talk therapy to help the client address some thought distortions that cause distress. Effective treatment of depression will require a combination of therapy (CBT, interpersonal therapy, cognitive therapy, and psychodynamic therapy) and medication. Adequate social support will also help the individual cope with depression.</p>
      </div>      
      {/* end Diagnosis and treatment */}

      <Footer/>
    </div>
  )
}

export default Depression