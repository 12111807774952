import React from "react";

// Assets
import "./Conditions.css";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ConditionsHero from "../../components/ConditionsHero/ConditionsHero";
import ConditionsWelcome from "../../components/ConditionsWelcome/ConditionsWelcome";
import ConditionItems from "../../components/ConditionItems/ConditionItems";

const Conditions = () => {
  return (
    <div className="Conditions">
        <Header/>
        <ConditionsHero/>
        <ConditionsWelcome/>
        <ConditionItems/>
        <Footer/>
    </div>
  )
}

export default Conditions