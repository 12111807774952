import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./InitialConsultsForm.css";


const InitialConsultsForm = () => {

  const [data, setData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    message: ""
  });

  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate fields
    const newErrors = {};
    if (!data.first_name) newErrors.first_name = "First name is required";
    if (!data.last_name) newErrors.last_name = "Last name is required";
    if (!data.email || !/^\S+@\S+\.\S+$/.test(data.email)) newErrors.email = "Valid email is required";
    if (!data.message) newErrors.message = "Message is required";

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setErrors({});
      try {
        const response = await fetch("/submit.php", {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          },
          body: new URLSearchParams(data)
        });

        if (response.ok) {
          navigate("/success");
        } else {
          const errorText = await response.text();
          console.error("Error submitting form:", errorText);
          throw new Error("Error submitting form");
        }
      } catch (error) {
        console.error(error);
        setErrors({ submit: "An error occurred while sending the message. Please try again later." });
      }
    }
  };
  

  return (
    <div className="InitialConsultsForm">
          <div className="contactUs-form">
            <form onSubmit={handleSubmit}>
              <label htmlFor="first_name">First Name<span className="highlight">*</span></label>
              <div className="two-col">
                <input
                  type="text"
                  id="first_name"
                  name="first_name"
                  className="input-form"
                  onChange={handleChange}
                  value={data.first_name}
                  placeholder="Enter your first name"
                  required
                />
                <input
                  type="text"
                  id="last_name"
                  name="last_name"
                  className="input-form"
                  onChange={handleChange}
                  value={data.last_name}
                  placeholder="Enter your surname"
                  required
                />
              </div>
              {errors.first_name && <span className="error">{errors.first_name}</span>}
              {errors.last_name && <span className="error">{errors.last_name}</span>}

              <label htmlFor="email">Email<span className="highlight">*</span></label>
              <div className="one-col">
                <input
                  type="text"
                  id="email"
                  name="email"
                  className="input-form"
                  onChange={handleChange}
                  value={data.email}
                  placeholder="mail@domain.com"
                  required
                />
              </div>
              {errors.email && <span className="error">{errors.email}</span>}

              <label htmlFor="message">Message<span className="highlight">*</span></label>
              <div className="one-col">
                <textarea
                  id="message"
                  name="message"
                  rows="12"
                  className="textarea"
                  onChange={handleChange}
                  value={data.message}
                  placeholder="Leave us a message"
                  required
                ></textarea>
              </div>
              {errors.message && <span className="error">{errors.message}</span>}

              <div className="two-col">
                <button type="submit" className="btn btn-secondry">Send</button>
              </div>
              {errors.submit && <span className="error">{errors.submit}</span>}
            </form>
          </div>        
    </div>
  )
}

export default InitialConsultsForm