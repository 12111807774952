import React from "react";

// Assets
import "./PersonalHistory.css";
import PersonalHistoryBanner from "../../images/your-personal-banner.jpg"

const PersonalHistory = () => {
  return (
    <div className="PersonalHistory">
        
        {/* Left Side */}
        <div className="phistory-left-side">
            <div className="title-ring">
                <h2 className="title">Your Personal History</h2>
                <img src={PersonalHistoryBanner} alt="your-personal-banner" className="banner" />
            </div>
        </div>

        {/* Right Side  */}
        <div className="phistory-right-side">
            <p className="htext">
                To help us learn more about you, you shall speak to one of our admin persons who will ask you questions, your presenting complaints, prevalent symptoms. You will be required to fill an initial consult form that will help us process your appointment. The expected process and procedure of your appointments with the Unforsaken will also be discussed during this call. An initial consultation will then be fixed where the client meets with the psychologist to gain an understanding of why they are seeking our services. 
            </p>
            <p className="htext">
                The initial consultations involve history taking using a semi-structure questioning format. The initial consultation usually takes 90 minutes. However, a follow-up appointment may be fixed if the questions for the initial consults were not exhausted during the first day.
            </p>
        </div>
    </div>
  )
}

export default PersonalHistory;