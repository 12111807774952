import React from "react";

// Assets
import "./Neurological.css";
import NeurologicalBanner from "../../images/neurological-banner.jpg";

const Neurological = () => {
  return (
    <div className="Neurological">
        <div className="neu-cont">
            <div className="left-neuro">
                <img src={NeurologicalBanner} alt="Neurological Banner" className="neuro-banner" />
            </div>
            <div className="right-neuro">
                <h1 className="neuro-title">Neurological Assessments</h1>
                <p className="neuro-text">
                To build our treatment plan for our clients, we may conduct neurological assessments with our clients if required. Not all clients need to go through these neurological assessments. It depends on what the client is presenting with. We go beyond the traditional psychological methods.
                </p>
            </div>
        </div>
    </div>
  )
}

export default Neurological