import React from "react";
import { NavLink as Link } from "react-router-dom";

// Assets
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import EatingDisordersHero from "../../images/eating-disorders-hero.jpg";
import { FaCirclePlay } from "react-icons/fa6";
import heroBG from "../../images/img-bg.svg";
import "./EatingDisorders.css";

const EatingDisorders = () => {

  
  // Click event that scroll to the top of the page
  const handleClickScroll = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="EatingDisorders">
      <Header/>

      {/* Hero Section */}
      <div className="landHero">
        <div className="hero-left-side">
            <div className="hero-head-text no-justify">Eating Disorders</div>
            <p className="hero-text">
              Eating disorders are serious health conditions that affect both one’s physical and mental health. These conditions include problems in how the person think about food, eating, weight and shape, and in the eating behaviors. These symptoms can affect one’s health, emotions and their ability to function in important areas of life. Eating disorders can lead to lifelong issues if not well-managed.
              <br />
              <br />
              The commonest eating disorders are anorexia, bulimia and binge-eating disorder.              
            </p>
            <Link to="/contact-us" onClick={handleClickScroll}><div className="btn btn-secondry">Contact Us Now</div></Link>
        </div>
        <div className="hero-right-side">
            <img src={EatingDisordersHero} alt="banner" className="stack inner-hero lhero-banner" />
            <img src={heroBG} alt="Hero-BG" className="stack img-bg" />
        </div>
      </div>
      {/* end Hero Section */}

      {/* Types of dissociative disorders */}
      <div className="Welcome" style={{alignItems: "flex-start"}}>
          <p className="sub-text" style={{textAlign: "center", marginBottom: "120px"}}>Most eating disorders involve focusing too much on weight, body shape and food. This can lead to unhealthy eating habits behaviors. These behaviors can impact significantly the ability to get the nutrition the body needs. Most eating disorders are linked to other mental conditions such as depression, body dysmorphia, anxiety and stress</p>
          <h1 className="welcome-header" style={{textAlign: "left"}}>Symptoms of Eating Disorders</h1>
          <h1 className="welcome-header" style={{textAlign: "left", fontSize: "22px", fontWeight: 600}}>Anorexia</h1>
          <p className="sub-text" style={{textAlign: "justify"}}>Anorexia also known as anorexia nervosa, can be a life-threatening eating disorder. It includes an unhealthy low body weight, intense fear of gaining weight, and a view of one’s weight and shape that is considered unusual. Anorexia often involves using extreme efforts to control weight and shape, which often seriously interfere with health and daily life.</p>
          <h1 className="welcome-header" style={{textAlign: "left", fontSize: "22px", fontWeight: 600}}>Symptoms of Anorexia</h1>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Having an intense fear of gaining weight.</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Being unable to realistically assess your body weight and shape (having a distorted self-image).</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Having an obsessive interest in food, calories and dieting</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Changes in eating habits or routines, such as eating foods in a certain order or rearranging foods on a plate</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">A sudden change in dietary preferences, such as eliminating certain food types or food groups</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Feeling overweight even if you are underweight</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Fear of certain foods or food groups</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Being very self-critical</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Denying the seriousness of your low body weight and/or food restriction</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Feeling a strong desire to be in control</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Feeling irritable and/or depressed</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Experiencing thoughts of self-harm or suicide</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Making frequent comments about feeling “fat” or overweight despite weight loss</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Purging through intentional vomiting and/or misusing laxatives or diuretics</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Going to the bathroom right after eating</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Using diet pills or appetite suppressants</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Compulsive and excessive exercising or extreme physical training</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Continuing to diet even when your weight is low for your sex, height and stature</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Making meals for others but not yourself</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Wearing loose clothing and/or wearing layers to hide weight loss and stay warm</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Withdrawing from friends and social events</p></div>
          
          <h1 className="welcome-header" style={{textAlign: "left", fontSize: "22px", fontWeight: 600, marginTop: "30px"}}>Bulimia</h1>
          <p className="sub-text" style={{textAlign: "justify"}}>Bulimia (buh-LEE-me-uh), also called bulimia nervosa, is a serious, sometimes life-threatening eating disorder. Bulimia includes episodes of bingeing, commonly followed by episodes of purging. Sometimes bulimia also includes severely limiting eating for periods of time. This often leads to stronger urges to binge eat and then purge.</p>
          <h1 className="welcome-header" style={{textAlign: "left", fontSize: "22px", fontWeight: 600}}>Symptoms of Bulimia</h1>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Being preoccupied with your body shape and weight</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Living in fear of gaining weight</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Repeated episodes of eating abnormally large amounts of food in one sitting</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Feeling a loss of control during binge eating</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Forcing yourself to vomit or exercising too much to keep from gaining weight after bingeing</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Using laxatives, diuretics or enemas after eating when they're not needed</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Fasting, restricting calories or avoiding certain foods between binges</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Using dietary supplements or herbal products excessively for weight loss</p></div>

          <h1 className="welcome-header" style={{textAlign: "left", fontSize: "22px", fontWeight: 600, marginTop: "30px"}}>Binge-eating Disorder</h1>
          <p className="sub-text" style={{textAlign: "justify"}}>
            Binge-eating disorder involves eating food in a short amount of time. When bingeing, it feels like there’s no control over eating. But binge eating is not followed by purging. During a binge, people may eat food faster or eat more food than planned. Even when not hungry, eating may continue long past feeling uncomfortably full.
            <br /><br />
            After a binge, people often feel a great deal of guilt, disgust or shame. They may fear gaining weight. They may try to severely limit eating for periods of time. This leads to increased urges to binge, setting up an unhealthy cycle. Embarrassment can lead to eating alone to hide bingeing. A new round of bingeing commonly occurs at least once a week.
          </p>
          <h1 className="welcome-header" style={{textAlign: "left", fontSize: "22px", fontWeight: 600}}>Symptoms of Binge-eating</h1>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Eating unusually large amounts of food in a specific amount of time, such as over a two-hour period</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Feeling out of control over eating</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Eating even when you're full or not hungry</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Eating rapidly during binge episodes</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Eating until you're uncomfortably full</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Frequently eating alone or in secret</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Feeling depressed, disgusted, ashamed, guilty or upset about your eating</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Frequently dieting, possibly without weight loss</p></div>

          <p className="sub-text" style={{textAlign: "justify", marginTop: "16px"}}>
            Unlike a person with bulimia, after a binge, you don’t regularly compensate for extra calories eaten by vomiting, using laxatives or exercising excessively. You may try to diet or eat normal meals. But restricting your diet may simply lead to more binge eating.
            <br /><br />
            The severity of binge-eating disorder is determined by how often episodes of bingeing occur during a week.
          </p>


          <h1 className="welcome-header" style={{textAlign: "left", fontSize: "22px", fontWeight: 600, marginTop: "30px"}}>Avoidant/Restrictive Food Intake Disorder</h1>
          <p className="sub-text" style={{textAlign: "justify", marginTop: "-20px"}}>
            Avoidant/restrictive food intake disorder includes extremely limited eating or not eating certain foods. The pattern of eating often doesn’t meet minimum daily nutrition needs. This may lead to problems with growth, development and functioning in daily life. But people with this disorder don’t have fears about gaining weight or body size. Instead, they may not be interested in eating or may avoid food with a certain colour, texture, smell or taste. Or they may worry about what can happen when eating. For example, they may have a fear of choking or vomiting, or they may worry about getting stomach problems.
          </p>
          <h1 className="welcome-header" style={{textAlign: "left", fontSize: "22px", fontWeight: 600, marginTop: "30px"}}>Symptoms of Avoidant/Restrictive Food Intake Disorder</h1>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Symptoms of Avoidant/restrictive food intake disorder</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Anxiety when presented with “fear” foods</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">For adults, weight loss; for children, failure to gain weight</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Dependence on nutritional supplements, a feeding tube or both</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Avoidance of particular foods, based on texture, color, taste, smell, food groups, etc</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Frequent vomiting or gagging after exposure to certain foods</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Difficulty chewing food</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Lack of appetite</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Trouble digesting specific types of foods</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Consumption of extremely small portions</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Dependence on external feeding tubes or nutritional supplements</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Social isolation</p></div>

          <Link to="/contact-us" onClick={handleClickScroll}><div className="btn btn-primary" style={{marginTop: "20px"}}>Contact Us Now</div></Link>


          <h1 className="welcome-header" style={{textAlign: "left", fontSize: "22px", fontWeight: 600, marginTop: "30px"}}>Causes of Eating Disorders</h1>
          <p className="sub-text" style={{textAlign: "justify", marginTop: "-20px"}}>
            The exact cause of eating disorders is not known. As with other mental health conditions, there may be different causes, such as: <br /><br />
            <span>Genetics</span>: Some people may have genes that increase their risk of developing eating disorders. <br />
            <span>Biology</span>: Biological factors, such as changes in brain chemicals, may play a role in eating disorders.
          </p>
          <h1 className="welcome-header" style={{textAlign: "left", fontSize: "22px", fontWeight: 600, marginTop: "30px"}}>Risk Factors to Eating Disorder</h1>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Family history</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Other mental health issues. Trauma, anxiety, depression</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Dieting and starvation.</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">A history of weight bullying</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Whether it's heading off to college, moving, landing a new job, or a family or relationship issue, change can bring stress. And stress may increase the risk of an eating disorder.</p></div>

          
          <h1 className="welcome-header" style={{textAlign: "left", fontSize: "22px", fontWeight: 600, marginTop: "30px"}}>Treatment of Eating Disorders</h1>
          <p className="sub-text" style={{textAlign: "justify", marginTop: "-20px"}}>
          <span>Behavioral Therapy</span>: Certain behavioral therapies can be effective in treating eating disorders. These include: <br /><br />
            <span>Family-based Treatment (FBT)</span>. FBT is an outpatient treatment for children and teenagers with anorexia. It also is likely effective for bulimia and other problem eating behaviors. The family is involved in making sure that the child or other family member follows healthy-eating patterns and maintains a healthy weight. <br />
            <span>Cognitive Behavioral Therapy (CBT)</span>. CBT is commonly used in eating disorder treatment, especially for bulimia, binge-eating disorder and some other problem eating behaviors. A type of CBT called enhanced CBT is used most often. You learn how to monitor and improve your eating habits and moods, develop problem-solving skills, and explore healthy ways to cope with stressful situations.
          </p>


          <h1 className="welcome-header" style={{textAlign: "left", fontSize: "22px", fontWeight: 600, marginTop: "30px"}}>Medicines</h1>
          <p className="sub-text" style={{textAlign: "justify", marginTop: "-20px"}}>Medication cannot exactly treat eating disorder. However, for bulimia or binge-eating disorder, some medications can be used manage urges to binge or purge or help manage an extreme focus on food and diet.</p>
          
          <Link to="/contact-us" onClick={handleClickScroll}><div className="btn btn-primary" style={{marginTop: "20px"}}>Contact Us Now</div></Link>
        </div>
      {/* end Types of dissociative disorders */}      

      <Footer/>    
    </div>
  )
}

export default EatingDisorders;