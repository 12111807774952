import React from "react";

// Assets
import "./Feedback.css";
import FeedbackImg from "../../images/feedback-banner.jpg";

const Feedback = () => {
  return (
    <div className="Feedbacks">
        <div className="feedback-img">
            <div className="line-one">
                <div className="green-line"></div>
                <div className="gray-line"></div>
                <div className="green-line"></div>
            </div>
            <div className="line-two"></div>
            <div className="line-three">
                <img src={FeedbackImg} alt="" className="feedback-banner" />
            </div>
        </div>
        <div className="feedback-content">
            <h1 className="feedback-title">Feedback Session</h1>
            <p className="feedback-text">
                Consider us as your accountability partner. We hold you by the hand through your journey with us. After your assessment, we would bring you in for a feedback session 10 working day after your assessment to enable us prepare your psychological report. The psychological report includes the history taken during the initial consults, the test results of the assessments, treatment plan and recommendations.  
            </p>
        </div>
    </div>
  )
}

export default Feedback;