import React, { useState } from "react";
import "./OurTeam.css";
import { TeamData } from "../data/Data";
// import { PopupButton } from "react-calendly";
import { NavLink as Link } from "react-router-dom";

const OurTeam = () => {
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedTeam, setSelectedTeam] = useState(null);

    const openModal = (team) => {
        setSelectedTeam(team);
        setModalVisible(true);
    };

    const closeModal = () => {
        setModalVisible(false);
        setSelectedTeam(null);
    };

    // Click event that scroll to the top of the page
    const handleClickScroll = () => {
        window.scrollTo(0, 0);
    }; 

    return (
        <div className="OurTeam">
            <div className="section-title">
                <h1 className="title">Our Team</h1>
                <div className="line"></div>
            </div>
            <p className="text">Our professionals consist of Clinical Psychologists, Counsellors and Consultants</p>

            <div className="team-cards">
                {TeamData.map((teamData, i) => (
                    <div
                        className="team-card"
                        key={i}
                        onClick={() => openModal(teamData)}
                    >
                        <img
                            src={teamData.teamPicture}
                            alt="Team"
                            className="team-image"
                        />
                        <p className="team-name">{teamData.teamName}</p>
                        <p className="team-role">{teamData.teamRole}</p>
                    </div>
                ))}
            </div>

            {modalVisible && selectedTeam && (
                <div className={`team-modal ${modalVisible ? "show" : ""}`} onClick={closeModal}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <div className="modal-header">
                            <span className="close" onClick={closeModal}>
                                &times;
                            </span>
                            <h2>{selectedTeam.teamName}</h2>
                        </div>
                        <div className="team-modal-body">
                            <div className="mb-l-side">
                                <img src={selectedTeam.teamBioPic} alt="Team" />
                            </div>
                            <div className="mb-r-side">
                                <p className="modal-role">{selectedTeam.teamDetailRole}</p>
                                <p className="modal-bio">{selectedTeam.teamBio}</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            
            <Link to="/book-session"  onClick={handleClickScroll}><div className="title-pill">Book a Session</div></Link>
            {/* <PopupButton
                url="https://calendly.com/theunforsaken"
                rootElement={document.getElementById("root")}
                text="Book a Session"
                className="title-pill"
                styles={{whiteSpace: "nowrap", width: "260px"}}
            /> */}
        </div>
    );
};

export default OurTeam;
