import React from "react";
// import { PopupButton } from "react-calendly";
import { NavLink as Link } from "react-router-dom";

// Assets
import "./ApproachWelcome.css"
import ApproachBanner from "../../images/Approach-banner.jpg";

const ApproachWelcome = () => {
      // Click event that scroll to the top of the page
  const handleClickScroll = () => {
    window.scrollTo(0, 0);
  }; 
    return (
        <div className="ApproachWelcome">
            <div className="approach-welcome-left">
                <div className="section-title">
                    <h1 className="title">Helping You<br />Grow in Every Stage</h1>
                    <div className="line"></div>
                </div>
                <p className="text">At the Unforsaken, we work with clients on individual basis. We consider the clients’ self-reports and also conduct evaluation that provides deeper understanding of the individual. We get to know more about the clients and conduct psychological and neurological assessments as required. The assessments that will be conducted will serve as vital tools in the mapping out a treatment plan for each client.</p>
                <Link to="/book-session"  onClick={handleClickScroll}><div className="btn btn-primary">Book a Session</div></Link>
                {/* <PopupButton
                    url="https://calendly.com/theunforsaken"
                    rootElement={document.getElementById("root")}
                    text="Book a Session"
                    className="btn btn-primary"
                    styles={{whiteSpace: "nowrap", width: "180px"}}
                /> */}
            </div>
            <div className="approach-welcome-right">
                <img src={ApproachBanner} alt="About Us Welcome Banner" className="welcome-banner" />
            </div>
        </div>
      )
}

export default ApproachWelcome;