import React from "react";
import { NavLink as Link } from "react-router-dom";
// import { PopupButton } from "react-calendly";

import "./ADHD.css";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import AdhdBanner from "../../images/adhd-banner.jpg";
import heroBG from "../../images/img-bg.svg";
import WelcomeBanner from "../../images/about-welcome-banner.jpg";
import { FaCirclePlay } from "react-icons/fa6";
import PersonalHistoryBanner from "../../images/your-personal-banner.jpg"

const ADHD = () => {
  
  // Click event that scroll to the top of the page
  const handleClickScroll = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="ADHD">
        {/* Header Section */}
        <Header/>
        {/* end Header Section */}

        {/* Hero Section */}
        <div className="landHero">
            <div className="hero-left-side">
                <div className="hero-head-text no-justify">Atetention Deficient Hyperactivity Disorder(ADHD)</div>
                <p className="hero-text">
                    Atetention Deficient Hyperactivity Disorder(ADHD) is a neurodevelopmental didorder that affects an individual's development and normal daily functioning. it is characterized by persistent issues like inattention, hyperactivity or impulsive behaviour.
                </p>
                <Link to="/contact-us" onClick={handleClickScroll}><div className="btn btn-secondry">Contact Us Now</div></Link>
            </div>
            <div className="hero-right-side">
                <img src={AdhdBanner} alt="banner" className="stack inner-hero lhero-banner" />
                <img src={heroBG} alt="Hero-BG" className="stack img-bg" />
            </div>
        </div>
        {/* end Hero Section */}

        {/* Signs of ADHD */}
        <div className="AboustUsWelcome">
            <div className="about-welcome-left points">
                <div className="section-title">
                    <h1 className="title">The following are <br /> major signs of ADHD</h1>
                    <div className="line"></div>
                </div>
                <p className="point-text"><FaCirclePlay className="point-icon" />Being unable to concentrate on tasks.</p>
                <p className="point-text"><FaCirclePlay className="point-icon" />Being unable to sit still</p>
                <p className="point-text"><FaCirclePlay className="point-icon" />Interrupting conversations</p>
                <p className="point-text"><FaCirclePlay className="point-icon" />Constantly fidgeting</p>
                <p className="point-text"><FaCirclePlay className="point-icon" />Excessive physical movement.</p>
                <p className="point-text"><FaCirclePlay className="point-icon" />Excessive talking</p>
                <p className="point-text"><FaCirclePlay className="point-icon" />Being unable to wait their turn</p>
                <p className="point-text"><FaCirclePlay className="point-icon" />Acting without thinking</p><br />
                <Link to="/book-session"  onClick={handleClickScroll}><div className="btn btn-primary">Book a Session</div></Link>
                {/* <PopupButton
                    url="https://calendly.com/theunforsaken"
                    rootElement={document.getElementById("root")}
                    text="Book a Session"
                    className="btn btn-primary"
                    styles={{whiteSpace: "nowrap", width: "180px"}}
                /> */}
            </div>
            <div className="about-welcome-right">
                <img src={WelcomeBanner} alt="About Us Welcome Banner" className="welcome-banner" />
            </div>
        </div>
        {/* end Signs of ADHD */}

        {/* Causes of ADHD */}
        <div className="PersonalHistory">
            
            {/* Left Side */}
            <div className="phistory-left-side">
                <div className="title-ring">
                    <h2 className="title">Causes of ADHD</h2>
                    <img src={PersonalHistoryBanner} alt="your-personal-banner" className="banner" />
                </div>
            </div>

            {/* Right Side  */}
            <div className="phistory-right-side">
                <p className="htext">
                    DHD can affect adults as well. Most times, diagnosis is not made until early adulthood. ADHD is diagnosed only when symptoms are severe enough to disrupt the individual’s life. 
                </p>
                <h2 className="title">There are possible causes of ADHD. There include:</h2>
                <h2 className="title">Genetics</h2>
                <p className="htext">
                    This happens when there is an individual living ADHD in the family. That means, it is hereditary and if there is a family member with ADHD, it could pre-dispose someone else in the family to have it.
                </p>
                <h2 className="title">Environment</h2>
                <p className="htext">
                Certain environmental factors also may trigger ADHD. They include: brain injury, exposure to lead, maternal smoking during pregnancy, sleep issues, complications at birth.
                </p>
            </div>
        </div>
        {/* end Causes of ADHD */}

        {/* Seperator */}
        <div className="Seperator"></div>
        {/* end Seperator */}

        {/* Treatment */}
        <div className="Welcome">
            <h1 className="welcome-header">Treatment</h1>
            <p className="sub-text">Treatments for ADHD usually involve medication, psychoeducation, skills training and psychotherapy. Combining these will be more effective for individual living with ADHD. Medication will help relieve some of the symptoms as presented in the individual. Psycho-educating the both parents and other significant others with the individual will help proper management. The psychosocial factor is very important for the individual with ADHD, to help them manage their normal daily activities. Also, certain life skills will be taught to the individuals that generally improve the individual’s daily functioning if properly implemented. Psychotherapy is very vital for the individual living with ADHD. Behavioural management skills will be taught, self-regulation and other strategies will be taught to help the individual cope with any distressing symptoms.</p>
            <Link to="/book-session"  onClick={handleClickScroll}><div className="btn btn-primary">Book a Session</div></Link>
            
            <br /><br />
            <h1 className="welcome-header">Common types of psychotherapy for ADHD include</h1>
            <p className="sub-text" style={{textAlign: "left"}}><span>Cognitive behavioral therapy</span>: This structured type of counseling teaches specific skills to manage your behavior and change negative thinking patterns into positive ones. It can help you deal with life challenges, such as school, work or relationship problems, and help address other mental health conditions, such as depression or substance misuse.</p>
            <p className="sub-text" style={{textAlign: "left"}}><span>Marital counseling and family therapy</span>: This type of therapy can help loved ones cope with the stress of living with someone who has ADHD and learn what they can do to help. Such counseling can improve communication and problem-solving skills.</p>

            <h1 className="welcome-header">Working on relationships</h1>
            <p className="sub-text">If you’re like many adults with ADHD, you may be unpredictable and forget appointments, miss deadlines, and make impulsive or irrational decisions. These behaviors can strain the patience of the most forgiving co-worker, friend or partner. <br /><br /> Therapy that focuses on these issues and ways to better monitor your behavior can be very helpful. So can classes to improve communication and develop conflict resolution and problem-solving skills. Couples therapy and classes in which family members learn more about ADHD may significantly improve your relationships.</p>
            <br />
            <Link to="/book-session"  onClick={handleClickScroll}><div className="btn btn-primary">Book a Session</div></Link>
        </div>
        {/* end Treatment */}

        {/* Footer */}
        <Footer/>
    </div>
  )
}

export default ADHD