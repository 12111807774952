import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import './App.css';

// Import Pages
import Home from "./pages/Home/Home";
import AboutUs from "./pages/AboutUs/AboutUs";
import OurApproach from "./pages/OurApproach/OurApproach";
import Conditions from "./pages/Conditions/Conditions";
import ContactUs from "./pages/ContactUs/ContactUs";
import ErrorPage from "./pages/ErrorPage/ErrorPage";
import ScrollToHashElement from "./components/ScrollToAnchor";
import BackToTopButton from "./components/BacktoTop/BackToTopButton";
import Success from "./pages/Success/Success";
import PatientCareProgram from "./pages/PatientCareProgram/PatientCareProgram";
import ADHD from "./pages/ADHD/ADHD";
import APersonalityDisorder from "./pages/APersonalityDisorder/APersonalityDisorder";
import AngerIssues from "./pages/AngerIssues/AngerIssues";
import Autism from "./pages/Autism/Autism";
import BehaviourAddictions from "./pages/BehaviourAddictions/BehaviourAddictions";
import BehaviourSchoolProblems from "./pages/BehaviourSchoolProblems/BehaviourSchoolProblems";
import BipolarDisorder from "./pages/BipolarDisorder/BipolarDisorder";
import BodyDysmorphicDisorder from "./pages/BodyDysmorphicDisorder/BodyDysmorphicDisorder";
import BorderlinePersonalityDisorder from "./pages/BorderlinePersonalityDisorder/BorderlinePersonalityDisorder";
import Depression from "./pages/Depression/Depression";
import DissociativeDisorders from "./pages/DissociativeDisorders/DissociativeDisorders";
import DrugsAlcoholAddiction from "./pages/DrugsAlcoholAddiction/DrugsAlcoholAddiction";
import EatingDisorders from "./pages/EatingDisorders/EatingDisorders";
import MaritalProblems from "./pages/MaritalProblems/MaritalProblems";
import NarcissisticPersonalityDisorder from "./pages/NarcissisticPersonalityDisorder/NarcissisticPersonalityDisorder";
import Blog from "./pages/Blog/Blog";
import BookSession from "./pages/BookSession/BookSession";
import InitialConsultPage from "./pages/InitialConsultPage/InitialConsultPage";


const App = () => {
    return <div id="home">
      <BrowserRouter>
            <ScrollToHashElement/>
        <Routes>
            <Route path="/" element={<Home />}/>
            <Route path="/book-session" element={<BookSession/>}/>
            <Route path="/initial-consult" element={<InitialConsultPage/>}/>
            <Route path="/about-us" element={<AboutUs />}/>
            <Route path="/our-approach" element={<OurApproach />}/>
            <Route path="/blog" element={<Blog />}/>
            <Route path="/in-patient-care-program" element={<PatientCareProgram />}/>
            <Route path="/contact-us" element={<ContactUs />}/>
            <Route path="/success" element={<Success />}/>
            <Route path="*" element={<ErrorPage />}/>
            <Route path="/conditions" element={<Conditions />}/>
            <Route path="/conditions/adhd" element={<ADHD/>}/>
            <Route path="/conditions/personality-disorder" element={<APersonalityDisorder/>}/>
            <Route path="/conditions/anger-issues" element={<AngerIssues/>}/>
            <Route path="/conditions/autism" element={<Autism/>}/>
            <Route path="/conditions/behaviour-addictions" element={<BehaviourAddictions/>}/>
            <Route path="/conditions/behaviour-school-problems" element={<BehaviourSchoolProblems/>}/>
            <Route path="/conditions/bipolar-disorder" element={<BipolarDisorder/>}/>
            <Route path="/conditions/body-dysmorphic-disorder" element={<BodyDysmorphicDisorder/>}/>
            <Route path="/conditions/borderline-personality-disorder" element={<BorderlinePersonalityDisorder/>}/>
            <Route path="/conditions/depression" element={<Depression/>}/>
            <Route path="/conditions/dissociative-disorders" element={<DissociativeDisorders/>}/>
            <Route path="/conditions/drugs-alcohol-addiction" element={<DrugsAlcoholAddiction/>}/>
            <Route path="/conditions/eating-disorders" element={<EatingDisorders/>}/>
            <Route path="/conditions/marital-problems" element={<MaritalProblems/>}/>
            <Route path="/conditions/Narcissistic-personality-disorder" element={<NarcissisticPersonalityDisorder/>}/>
        </Routes>
      </BrowserRouter>
    <BackToTopButton/>
  </div>
}

export default App;
