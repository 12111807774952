import React from "react";
import { NavLink as Link } from "react-router-dom";

// Assets
import "../../components/AboutHero/AboutHero.css";
import ApproachBanner from "../../images/approach-hero-banner.jpg";
import heroBG from "../../images/img-bg.svg";

const ApproachHero = () => {

  // Click event that scroll to the top of the page
  const handleClickScroll = () => {
    window.scrollTo(0, 0);
  }; 
    
    return (
        <div className="landHero">
            <div className="hero-left-side">
              <div className="hero-head-text">
                How the Unforsaken Works
              </div>
              <p className="hero-text">
              The Unforsaken House of Hephzibah adopts multidisciplinary approach to managing psychological and emotional issues which is different from traditional psychology methods. We try to understand how the mind and body interacts within the context of an individual’s life, which consists of the biological, psychological, social and spiritual aspects.
              </p>
              <Link to="/book-session"  onClick={handleClickScroll}><div className="btn btn-secondry">Book a Session</div></Link>
              {/* <PopupButton
                url="https://calendly.com/theunforsaken"
                rootElement={document.getElementById("root")}
                text="Book a Session"
                className="btn btn-secondry"
                styles={{whiteSpace: "nowrap", width: "180px"}}
              /> */}
            </div>
            <div className="hero-right-side">
              <img src={ApproachBanner} alt="About us Hero banner" className="stack inner-hero" />
              <img src={heroBG} alt="Hero-BG" className="stack img-bg" />
    
            </div>
        </div>
      )
}

export default ApproachHero