import React from "react";
import { NavLink as Link } from "react-router-dom";

// Assets
import "./BehaviourSchoolProblems.css";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import BehaviourSchoolProblemsHero from "../../images/behaviour-problems-banner.jpg";
import { FaCirclePlay } from "react-icons/fa6";
import heroBG from "../../images/img-bg.svg";

const BehaviourSchoolProblems = () => {
  
  // Click event that scroll to the top of the page
  const handleClickScroll = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="BehaviourSchoolProblems">
      <Header/>
      {/* Hero Section */}
      <div className="landHero">
        <div className="hero-left-side">
            <div className="hero-head-text no-justify">Behaviour & School Problems</div>
            <p className="hero-text">
              The behavior and school problems involves any behavior of the child which create difficulties in his/her learning process. This could affect the overall behaviour of the child at school. Some children get involved in school or behavioural problems as a result of certain environmental factors. 
            </p>
            <Link to="/contact-us" onClick={handleClickScroll}><div className="btn btn-secondry">Contact Us Now</div></Link>
        </div>
        <div className="hero-right-side">
            <img src={BehaviourSchoolProblemsHero} alt="banner" className="stack inner-hero lhero-banner" />
            <img src={heroBG} alt="Hero-BG" className="stack img-bg" />
        </div>
        </div>
        {/* end Hero Section */}

        {/* Possible causes of problematic behaviors in children */}
        <div className="Welcome" style={{alignItems: "flex-start"}}>
          <h1 className="welcome-header" style={{textAlign: "left"}}>Possible causes of problematic behaviors in children</h1>
          <div className="treat-point" style={{textAlign: "left"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Poor classroom environment</p></div>
          <div className="treat-point" style={{textAlign: "left"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Lack of social skills</p></div>
          <div className="treat-point" style={{textAlign: "left"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Unmet needs of the child</p></div>
          <div className="treat-point" style={{textAlign: "left"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Ineffective teachers who may not be sensitive enough to the children in their care</p></div>
          <div className="treat-point" style={{textAlign: "left"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Peer pressure</p></div>
          <div className="treat-point" style={{textAlign: "left"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Poor self-image</p></div>
          <div className="treat-point" style={{textAlign: "left"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Family dynamicse</p></div>
          <div className="treat-point" style={{textAlign: "left"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Cultural differences</p></div>
          <div className="treat-point" style={{textAlign: "left"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Lack of self-confidence</p></div>
        </div>

        <div className="Welcome" style={{alignItems: "flex-start", marginTop: "-110px"}}>
          <h1 className="welcome-header" style={{textAlign: "left", fontSize: "22px", fontWeight: 600}}>Management of Behaviour and Academic Problems</h1>
          <p className="hero-text" style={{fontSize: "14px", color: "#545454", marginTop: "-20px", textAlign: "justify"}}>
            Behavioural management therapy is used for children, adolescents, and adults in managing some deviant behaviours. BMT focuses on the reinforcement of desirable behaviors and reduce undesirable behaviors. BMT also promotes learning new behaviours, unlearning undesirable behaviour and relearning desirable behaviour.
          </p>
          <Link to="/contact-us" onClick={handleClickScroll} style={{marginTop: "20px"}}><div className="btn btn-secondry">Contact Us Now</div></Link>
        </div>
        {/* end Possible causes of problematic behaviors in children */}


      <Footer/>
    </div>
  )
}

export default BehaviourSchoolProblems;