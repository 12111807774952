import React from "react";


import "./Home.css";
import Header from "../../components/Header/Header";
import Hero from "../../components/Hero/Hero";
import Welcome from "../../components/Welcome/Welcome";
import OurServices from "../../components/OurServices/OurServices";
import History from "../../components/History/History";
import Principles from "../../components/Principles/Principles";
import Newsletter from "../../components/Newsletter/Newsletter";
import FAQs from "../../components/FAQs/FAQs";
import Testimonials from "../../components/Testimonials/Testimonials";
import Footer from "../../components/Footer/Footer";

const Home = () => {
  return (
    <div className="Home">
      <Header/>
      <Hero/>
      <Welcome/>       
      <OurServices/>
      <History/>
      <Principles/>
      <Newsletter/>
      <FAQs/>
      <Testimonials/>
      <Footer/>
    </div>
  )
}

export default Home