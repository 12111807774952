import React from "react";
import { useEffect, useState } from "react";
import "./BackToTopButton.css";

import { BiSolidArrowToTop } from "react-icons/bi";


const BackToTopButton = () => {
    const [backToTopButton, setBackToTopButton] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if(window.scrollY > 100) {
                setBackToTopButton(true)
            } else {
                setBackToTopButton(false)
            }
        })
    }, [])

    const scrollUp = () => {
        window.scrollTo ({
            top: 0,
            behavior: "smooth"
        })
    }

  return (
    <div>
        {backToTopButton && (
            <div 
                className="scroll-top"
                style={{
                    position: "fixed",
                    bottom: "20px",
                    right: "20px",
                }}
                onClick={scrollUp}
            >
                <BiSolidArrowToTop/>
            </div>
        )}
    </div>
  )
}

export default BackToTopButton;