import React from "react";

// Assets
import "./Seperator.css";

const Seperator = () => {
  return (
    <div className="Seperator"></div>
  )
}

export default Seperator