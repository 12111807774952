import React from "react";
// import { PopupButton } from "react-calendly";
import { NavLink as Link } from "react-router-dom";

// Assets
import "./Principles.css";
import Mission from "../../images/mission-banner.jpg";
import Vision from "../../images/vision-banner.jpg";
import CoreValues from "../../images/core-values-banner.jpg";
import LeftEdge from "../../images/l-edge.png";
import RightEdge from "../../images/r-edge.png";

const Principles = () => {
// Click event that scroll to the top of the page
  const handleClickScroll = () => {
    window.scrollTo(0, 0);
  }; 

  return (
    <div className="Principles">
        <div className="top-prin">
            {/* Title */}
            <div className="prin-title">
                <div className="t-title">Our <span className="highlight">Principles</span></div>
                <div className="b-title">WHAT GUIDES US</div>
            </div>

            <p className="text">What guides us to give exceptional services and makes us stand out from the rest. Each Person, Couple and Family has a Unique Psychological Journey! </p>
            <Link to="/book-session"  onClick={handleClickScroll}><div className="btn btn-primary">Book a Session</div></Link>
            {/* <PopupButton
                url="https://calendly.com/theunforsaken"
                rootElement={document.getElementById("root")}
                text="Book a Session"
                className="btn btn-primary"
                // styles={{whiteSpace: "nowrap", width: "180px"}}
            /> */}
        </div>


        {/* Buttom Content */}
        <div className="bottom-prin">
            <div className="card-l">
                <img src={Mission} alt="Mission Banner" loading="lazy" className="banner" />

                <div className="card-content">
                    <div className="card-title">
                        Our <br /><span className="highlight">Mission</span>
                    </div>
                    <p className="card-text">To deliver wholistic psychosocial support services to clients using modern psychological and scriptural principles.</p>
                    <Link to="/about-us" onClick={handleClickScroll}><div className="btn btn-primary">Learn More</div></Link>
                </div>

                <div className="card-footer">
                    <img src={LeftEdge} alt="" className="left-edge" />
                    <img src={RightEdge} alt="" className="right-edge" />
                </div>
            </div>

            {/* Vision */}
            <div className="card-m">
                <img src={Vision} alt="Mission Banner" loading="lazy" className="banner" />

                <div className="card-content">
                    <div className="card-title">
                        Our <br /><span className="highlight">Vision</span>
                    </div>
                    <p className="card-text">Our Vision is a society where people attain “holistic” health in mind, spirit and body using Scriptural and Psychological interventions.</p>
                    <Link to="/about-us" onClick={handleClickScroll}><div className="btn btn-primary">Learn More</div></Link>
                </div>

                <div className="card-footer">
                    <img src={LeftEdge} alt="" className="left-edge" />
                    <img src={RightEdge} alt="" className="right-edge" />
                </div>
            </div>

            <div className="card-r">
                <img src={CoreValues} alt="Mission Banner" loading="lazy" className="banner" />

                <div className="card-content">
                    <div className="card-title">
                        Our <br /><span className="highlight">Values</span>
                    </div>
                    <p className="card-text">Our Support are: Genuine, Person centered, Non- judgmental, Relationship oriented Built on individual’s optimal capacity, Result oriented.</p>
                    <Link to="/about-us" onClick={handleClickScroll}><div className="btn btn-primary">Learn More</div></Link>
                </div>

                <div className="card-footer">
                    <img src={LeftEdge} alt="" className="left-edge" />
                    <img src={RightEdge} alt="" className="right-edge" />
                </div>
            </div>
        </div>
    </div>
  )
}

export default Principles;