import React from "react";
import { NavLink as Link } from "react-router-dom";

// Assets
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import DissociativeDisordersHero from "../../images/dissociative-disorders-hero.jpg";
import { FaCirclePlay } from "react-icons/fa6";
import heroBG from "../../images/img-bg.svg";
import "./DissociativeDisorders.css";

const DissociativeDisorders = () => {
  
  // Click event that scroll to the top of the page
  const handleClickScroll = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="DissociativeDisorders">
      <Header/>

      {/* Hero Section */}
      <div className="landHero">
        <div className="hero-left-side">
            <div className="hero-head-text no-justify">Dissociative Disorders</div>
            <p className="hero-text">
              Dissociative disorders are mental health conditions that involve loss of connection between, feelings, thoughts, memories, surroundings, behavior and self-identity. These conditions include escape from reality in ways that are not wanted and not healthy. This causes problems in managing everyday life.
            </p>
            <Link to="/contact-us" onClick={handleClickScroll}><div className="btn btn-secondry">Contact Us Now</div></Link>
        </div>
        <div className="hero-right-side">
            <img src={DissociativeDisordersHero} alt="banner" className="stack inner-hero lhero-banner" />
            <img src={heroBG} alt="Hero-BG" className="stack img-bg" />
        </div>
      </div>
      {/* end Hero Section */}

      {/* Types of dissociative disorders */}
      <div className="Welcome" style={{alignItems: "flex-start"}}>
          <h1 className="welcome-header" style={{textAlign: "left"}}>Types of Dissociative Disorders</h1>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Dissociative Identity Disorder</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Depersonalization</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Derealization</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Dissociative Amnesia</p></div>
          <p className="sub-text" style={{textAlign: "justify", marginTop: "20px"}}>Dissociative symptoms can potentially disrupt every area of mental functioning. Examples of dissociative symptoms include the experience of detachment or out of body feeling.</p>
        </div>
      {/* end Types of dissociative disorders */}

      {/* Symptoms of Dissociative disorders */}
      <div className="Welcome" style={{alignItems: "flex-start", marginTop: "-100px"}}>
          <h1 className="welcome-header" style={{textAlign: "left"}}>Symptoms of Dissociative Disorders</h1>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Significant memory loss of specific times, people and events.</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Out of body experiences, such as feeling as though you are watching a movie of yourself.</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Mental health problems such as depression, anxiety and thoughts of suicide.</p></div>
          <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">A sense of detachment from your emotions, or emotional numbness.</p></div>
        </div>
      {/* end Symptoms of Dissociative disorders */}

      {/* Causes of Dissociative disorders */}
      <div className="Welcome" style={{alignItems: "flex-start", marginTop: "-90px"}}>
        <h1 className="welcome-header" style={{textAlign: "left", fontSize: "22px", fontWeight: 600}}>Causes of Dissociative disorders</h1>
        <p className="sub-text" style={{textAlign: "justify"}}>Dissociative disorders caused by abuse or trauma in childhood.</p>
        <h1 className="welcome-header" style={{textAlign: "left", fontSize: "22px", fontWeight: 600}}>Diagnosis of Dissociative Disorders</h1>
        <p className="sub-text" style={{textAlign: "justify"}}>Your doctor will ask some questions, taking in your personal history and understanding there are underlying cause. Some tools such as Rorschach Inkblot Test, Minnesota Multiphasic Personality Inventory–2, Wechsler Adult Intelligence Scale– Revised, Millon Clinical Multiaxial Inventory–III may be used in the diagnosis.</p>
        <h1 className="welcome-header" style={{textAlign: "left", fontSize: "22px", fontWeight: 600}}>Treatment of Dissociative Disorders</h1>
        <p className="sub-text" style={{textAlign: "justify"}}>Talking therapies are the recommended treatment for dissociative disorders. Counselling or psychotherapy can help the person feel safer in themselves. During therapy, the psychologist will help the person assess any abuse or traumatic experience in the past.</p>
        <Link to="/contact-us" onClick={handleClickScroll}><div className="btn btn-primary" style={{marginTop: "20px"}}>Contact Us Now</div></Link>
      </div>       
      {/* Causes of Dissociative disorders */}
      <Footer/>
    </div>
  )
}

export default DissociativeDisorders