import React from "react";
import "./Maps.css";

const Map = () => {
  return (
    <div className="Maps">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.67697998556!2d3.472384638427449!3d6.4355193751246045!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103bf5b248f28ad5%3A0x5dc7665c77454950!2s46%20Olabanji%20Olajide%20St%2C%20Lekki%20Phase%20I%2C%20Lekki%20106104%2C%20Lagos!5e0!3m2!1sen!2sng!4v1720086143328!5m2!1sen!2sng"
        width="646"
        height="310"
        style={{ 
          border: 1,
          borderRadius: 30, 
          marginTop: 40
        }}
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        className="Maps-frame"
      ></iframe>
    </div>
  );
};

export default Map;
