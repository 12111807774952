import React, { useState } from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
    AccordionItemState,
} from "react-accessible-accordion";
import { CiCirclePlus } from "react-icons/ci";

// Assets
import "./FAQs.css";
import { FaqsData } from "../data/Data";
import ChatIcon from "../../images/chat-icon.png";
import ComsIcon from "../../images/coms-icon.png";
import SupportIcon from "../../images/support.svg";


const FAQs = () => {
    const [className, setClassName] = useState(null);

  return (
    <div className="FAQs">

        {/* Title */}
        <h1 className="faqs-title">Frequently Asked Question</h1>

        {/* Bottom of FAQs */}
        <div className="faqs-content">

            {/* Left Side */}
            <div className="left-faq">

                <div className="faq-pill1">
                    <div className="pill-icon">
                        <img src={ChatIcon} alt="Chat Icon" />
                    </div>
                    <div className="pill-content">
                        <div className="title">Get Much Needed Help</div>
                        <div className="text">We understand how access to care is</div>
                    </div>
                </div>

                <div className="faq-pill2">
                    <div className="pill-icon">
                        <img src={ComsIcon} alt="Chat Icon" />
                    </div>
                    <div className="pill-content">
                        <div className="title">Communicate Your Way</div>
                    </div>
                </div>

                <div className="faq-pill1">
                    <div className="pill-icon">
                        <img src={SupportIcon} alt="Chat Icon" />
                    </div>
                    <div className="pill-content">
                        <div className="title">Contact Live Chat</div>
                        <div className="text">Round the clock support</div>
                    </div>
                </div>



            </div>

            {/* Right Side */}
            <div className="right-faq">
                <div className="faq-items">
                    <Accordion
                    className="accordion"
                    allowMultipleExpanded={false}
                    preExpanded={[0]}
                    >
                    {FaqsData.map((faqItem, i) => {
                        return (
                        <AccordionItem className={`accordionItem ${className}`} key={i} uuid={i}>
                            <AccordionItemHeading>
                            <AccordionItemButton className="accordionButton">
                                <AccordionItemState>
                                {({ expanded }) =>
                                    expanded
                                    ? setClassName("expanded")
                                    : setClassName("collapsed")
                                }
                                </AccordionItemState>
                                <div className="icon"><CiCirclePlus size={22} color="#004d00"/></div>
                                <div className="question">{faqItem.question}</div>
                            </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                            <p className="answer">{faqItem.answer}</p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        );
                    })}
                    </Accordion>
                </div>
            </div>
        </div>
    </div>
  )
}

export default FAQs;