import React from "react";
import { NavLink as Link } from "react-router-dom";

// Assets
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import MaritalProblemsHero from "../../images/marital-problems-hero.jpg";
import { FaCirclePlay } from "react-icons/fa6";
import heroBG from "../../images/img-bg.svg";
import "./MaritalProblems.css";

const MaritalProblems = () => {
  
  // Click event that scroll to the top of the page
  const handleClickScroll = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="MaritalProblems">
      <Header/>

      {/* Hero Section */}
      <div className="landHero">
        <div className="hero-left-side">
            <div className="hero-head-text no-justify">Marital Problems</div>
            <p className="hero-text">
              Marital problems stem from poor communication, lack of intimacy, money problems, and growing apart as life takes different twists and turns. If you realize that your marriage is hitting a rough patch, but both you and your spouse are willing to make some necessary changes, you can resolve almost any source of tension.
              <br /><br />
              Marital distress has powerful effects on the partners, often leading to great sadness, worry, a high level of tension, anxiety, and depression. And, if prolonged, it can negatively impact one’s physical health. The effect on families is also profound, especially when conflict is high.            
            </p>
            <Link to="/contact-us" onClick={handleClickScroll}><div className="btn btn-secondry">Contact Us Now</div></Link>
        </div>
        <div className="hero-right-side">
            <img src={MaritalProblemsHero} alt="banner" className="stack inner-hero lhero-banner" />
            <img src={heroBG} alt="Hero-BG" className="stack img-bg" />
        </div>
      </div>
      {/* end Hero Section */}

      {/* What is marriage counselling */}
      <div className="Welcome" style={{alignItems: "flex-start"}}>
        <h1 className="welcome-header" style={{textAlign: "left"}}>What is Marriage Counselling</h1>
        <p className="sub-text" style={{textAlign: "justify"}}>
          This is talk therapy that helps married couples discuss their challenges in safe and confidential environment. At the Unforsaken, we give married couples the opportunity to talk about their marital problems and find healthy ways to resolve it.
          <br /><br />
          Marriage helps couples improve their relationship. With the help of a professional, it gives a couple the much-needed guidance to develop a healthy attitude towards their relationship.
        </p>
        <h1 className="welcome-header" style={{textAlign: "left", fontSize: "22px", fontWeight: 600}}>Types of Marital Therapy</h1>
        <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Insight-gaining therapy</p></div>
        <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Communication counseling</p></div>
        <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Attachment therapy</p></div>
        <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Psychodynamic couples counseling</p></div>
        <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Gottman method of couples therapy</p></div>
        <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Positive psychology couples therapy</p></div>
        <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Emotional focused therapy</p></div>
        <div className="treat-point" style={{textAlign: "justify"}}><FaCirclePlay className="point-icon" style={{marginTop: "4px" , color: "#545454", fontSize: "14px"}} /><p className="point-text">Imago relationship therapy</p></div>
        <Link to="/contact-us" onClick={handleClickScroll}><div className="btn btn-primary" style={{marginTop: "20px"}}>Contact Us Now</div></Link>
      </div>
      {/* end What is marriage counselling */}

      <Footer/>
    </div>
  )
}

export default MaritalProblems;